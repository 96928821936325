import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { dialog } from '$gcomponents/reusables';
import { Header, TableView2 } from '$gcomponents/widgets';
import ItemActionModel from '$gbusiness/models/itemAction';
import { storeActions } from '$fbusiness/redux/store';

import STORE_TABLE, { defaultStoreColumns, STORE_TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import CurrentStateModel from '$fbusiness/models/currentState';
import PATH from '$business/enums/paths';
import FabButton from '$fcomponents/fabButton';
import { accessHide, isAccessible, updatedFilter } from '$fbusiness/helpers/util';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import StoreFilter, { defaultStoreFilter } from './storeFilter';
import { userActions } from '$fbusiness/redux/user';
import StoreModel from '$fbusiness/models/store';
import UserModel from '$gbusiness/models/user';
import FactoryModel from '$fbusiness/models/factory';
import { itemText } from '$gbusiness/helpers/util';
import intl from '$gintl';
import { CUSTOMERS } from '$fbusiness/enums/columns';
import { ACCESS } from '$fbusiness/enums/access';

interface StoresScreenProps {
  history: any;
  factory: FactoryModel;
  deleteFactory: Function;
  fetchStores: Function;
  stores: Array<StoreModel>;
  users: Array<UserModel>;
  columns: any;
  currentState: CurrentStateModel;
}

const StoresScreen: React.FC<StoresScreenProps> = ({
  history,
  factory,
  currentState,
  stores,
  columns,
  users,
  deleteFactory,
  fetchStores,
}) => {
  const [filter, setFilter] = useState<any>({ ...defaultStoreFilter });

  const updateFilter = (newFilter: any = {}) => {
    const { user, store, ...rest } = newFilter;
    setFilter(updatedFilter(filter, rest));
  };

  const itemActions: Array<ItemActionModel> = [
    {
      labelText: itemText('EDIT', intl('COMMON.STORE')),
      eventName: 'edit',
      disabled: () => !isAccessible(ACCESS.ACTION.STORE.EDIT, currentState),
      onClick: (row) => {
        history.push(`${PATH.STORES}/${row.id}`);
      },
    },
    ...(factory?.settings?.createInvoice
      ? [
          {
            labelText: itemText('create_new', 'INVOICE'),
            eventName: 'create',
            disabled: () => !isAccessible(ACCESS.ACTION.INVOICE.CREATE, currentState),
            onClick: (row) => history.push(`${PATH.INVOICE}/0/0/${row.id}`),
          },
        ]
      : []),
    ...(factory?.settings?.refundVoucher
      ? [
          {
            labelText: itemText('create_new', 'REFUND'),
            eventName: 'refund',
            disabled: () => !isAccessible(ACCESS.ACTION.REFUND.CREATE, currentState),
            onClick: (row) => history.push(`${PATH.CREATE_REFUND}/0/${row.id}`),
          },
        ]
      : []),
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      disabled: () => !isAccessible(ACCESS.ACTION.STORE.DELETE, currentState),
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteFactory(row.id);
            await fetchStores();
            updateFilter();
          },
        });
      },
    },
  ];

  const storeColumns =
    columns && columns[CUSTOMERS] ? columns[CUSTOMERS] || defaultStoreColumns : defaultStoreColumns;

  return (
    <IonPageWrapper>
      <Header title="SCREEN.STORES.TITLE" />
      <TableView2
        tableConfig={STORE_TABLE_CONFIG}
        TABLE={STORE_TABLE(currentState.settings, currentState)}
        filter={filter}
        columns={storeColumns}
        columnKey={CUSTOMERS}
        itemActions={itemActions}>
        <FilterSection title={null}>
          <StoreFilter
            settings={currentState.settings}
            regions={factory.regions}
            stores={stores}
            users={users}
            columns={storeColumns}
            parentFilter={filter}
            onSearch={updateFilter}
          />
        </FilterSection>
      </TableView2>
      <FabButton route={PATH.STORES + '/0'} className={accessHide(ACCESS.ACTION.STORE.CREATE)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  users: state.user.users,
  columns: state.localStorage.columns,
  resetOnRoute: true,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: (params) => {
    dispatch(userActions.fetchUsers({ storeUser: true, storeId: 1 }));
  },
  deleteFactory: (n) => dispatch(storeActions.deleteStore(n)),
  fetchStores: () => dispatch(storeActions.fetchStores()),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(StoresScreen));

import { toLocal } from '$gbusiness/helpers/date';
import { differenceInDays, parseISO } from 'date-fns';
import ItemModel, { deriveRawToItem } from './item';
import VendorModel, { deriveRawToVendor } from './vendor';
import SerialModel from './serial';
import WarehouseModel, { deriveRawToWarehouse } from './warehouse';

export default interface ItemBatchModel {
  id: number;
  receiveNo: string;
  quantity: number;
  cost: number;
  status: string;
  receiveDate: string;
  sellByDate?: string;
  expirationDate: string;
  expireThreshold: number;
  expireThreshold2: number;
  remainingDays?: number;
  binNo: string;
  lotNo: string;
  memo: string;
  serials: Array<SerialModel>;
  warehouse?: WarehouseModel;
  item?: ItemModel;
  vendor?: VendorModel;
  settings?: any;
  createdAt?: string;
}

export const deriveRemainingDays = (raw) => {
  if (!raw.expirationDate) return 9999;
  const todayDate = new Date();
  const expireDate = parseISO(raw.expirationDate);
  const days = differenceInDays(expireDate, todayDate);
  return days;
};

export const deriveRawToItemBatch = (raw, simple = false) => {
  return {
    id: raw.id,
    quantity: raw.quantity,
    cost: raw.cost,
    status: raw.status,
    receiveDate: raw.receiveDate,
    expirationDate: raw.expirationDate,
    expireThreshold: raw.expireThreshold,
    expireThreshold2: raw.expireThreshold2,
    remainingDays: deriveRemainingDays(raw),
    warehouse: deriveRawToWarehouse(raw.warehouse || undefined),
    memo: raw.memo || '',
    ...(raw.receiveNo && { receiveNo: raw.receiveNo }),
    ...(raw.sellByDate && { sellByDate: raw.sellByDate }),
    serials: raw.serials || [],
    binNo: raw.binNo || '',
    lotNo: raw.lotNo || '',
    ...(raw.item && !simple && { item: deriveRawToItem(raw.item) }),
    ...(raw.vendor && { vendor: deriveRawToVendor(raw.vendor), vendorId: raw.vendorId }),
    ...(raw.settings && { settings: Array.isArray(raw.settings) ? {} : raw.settings }),
    createdAt: toLocal(raw.createdAt),
  };
};

export const initialBatch: ItemBatchModel = {
  id: 0,
  receiveNo: '0',
  quantity: 0,
  cost: 0,
  status: '',
  receiveDate: '',
  sellByDate: '',
  expirationDate: '',
  expireThreshold: 30,
  expireThreshold2: 60,
  serials: [],
  memo: '',
  binNo: '',
  lotNo: '',
};

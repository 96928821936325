import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import StoreModel from '$fbusiness/models/store';
import { configs } from '$configs';
import BROADCAST_TABLE, { BROADCAST_TABLE_CONFIG } from './table';
import CurrentStateModel from '$fbusiness/models/currentState';
import BroadcastModal from './broadcastModal';
import { IonContent } from '@ionic/react';
import FabButton from '$fcomponents/fabButton';
import PATH from '$business/enums/paths';
import { accessHide } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';
import { broadcastActions } from '$fbusiness/redux/broadcast';

interface BroadcastsScreenProps {
  store: StoreModel;
  currentState: CurrentStateModel;
  updateBroadcast: Function;
}

// Function to add id to array if it does not exist
function addIdToArray(id, array) {
  if (!array.includes(id)) {
    array.push(id);
  }
  return array;
}

const Header =
  configs.display.layout === 'FIXED_HEADER' ? (
    <FactoryHeader title="SCREEN.BROADCAST.TITLE" />
  ) : (
    <FixedHeader title="MENU.NOTIFICATION" />
  );

const BroadcastsScreen: React.FC<BroadcastsScreenProps> = ({ store, currentState, updateBroadcast }) => {
  const [broadcast, setBroadcast] = useState<any>(null);
  const [filter] = useState<any>({ query: '', forceRefresh: true, storeId: store?.id });
  const { isFactory } = currentState;

  const onClickMessage = (row) => {
    setBroadcast(row);
    if (isFactory) return;
    const newRead = addIdToArray(store?.id, row.read || []);
    updateBroadcast(row.id, { read: newRead });
  };

  const broadcastTable = BROADCAST_TABLE(isFactory);

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <TableView2
          tableConfig={BROADCAST_TABLE_CONFIG(onClickMessage)}
          TABLE={broadcastTable}
          filter={filter}
        />
        <BroadcastModal broadcast={broadcast} show={!!broadcast} onClose={() => setBroadcast(null)} />
      </IonContent>
      {isFactory && (
        <FabButton route={PATH.NOTIFICATION} className={accessHide(ACCESS.ACTION.EMAIL_NOTIFICATION)} />
      )}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  store: state.store.store,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  updateBroadcast: broadcastActions.updateBroadcast,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(BroadcastsScreen));

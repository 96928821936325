import React, { useEffect } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';

import DiscountModel from '$fbusiness/models/discount';
import { FormSection } from '$gcomponents/reusables';
import { DISCOUNT_FORM } from './discountForm';
import { input } from '$gbusiness/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux';
import Footer from '$gcomponents/widgets/footer';
import { isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface DiscountDetailsProps {
  onClose: Function;
  discount: DiscountModel;
  currentState;
}

const DiscountDetails: React.FC<DiscountDetailsProps> = ({ discount, onClose, currentState }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
  }, [isFinished, onClose, dispatch]);

  const onSubmit = (values) => {
    const { id } = discount;
    dispatch(factoryActions.saveDiscount(id, values));
  };

  const validateForm = (values) => input.validateError(DISCOUNT_FORM, values);
  const { name, amount, isFlat } = discount;

  const canSave = isAccessible(
    discount?.id ? ACCESS.ACTION.SETTING.COMMISSION_RULE.EDIT : ACCESS.ACTION.SETTING.COMMISSION_RULE.CREATE,
    currentState,
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name,
        amount,
        isFlat,
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={DISCOUNT_FORM} formik={formik} />
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="center">
            <Button
              className="half"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty) || !canSave}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default DiscountDetails;

import React from 'react';
import { Div } from '$gstyles';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';

interface FormikFormProps {
  myForm: any;
  initialValues: any;
  onSubmit: Function;
  titleKey?: string;
  lastColumn?: React.ReactElement;
  afterRender?: React.ReactElement;
  submitButton?: React.ReactElement;
}

const FormikForm: React.FC<FormikFormProps> = ({
  myForm,
  initialValues,
  titleKey,
  lastColumn,
  afterRender,
  submitButton,
  onSubmit,
}) => {
  if (!myForm) return null;
  const validateForm = (values) => input.validateError(myForm, values);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => {
        const submitButtonNode =
          submitButton && submitButton.props.onClick
            ? React.cloneElement(submitButton, { onClick: formik.handleSubmit })
            : null;
        const lastColumnNode = lastColumn
          ? React.cloneElement(lastColumn, {
              ...(!lastColumn.props.onClick && { onClick: formik.handleSubmit }),
            })
          : null;
        const afterRenderNode =
          afterRender && afterRender.props.onClick
            ? React.cloneElement(afterRender, { onClick: formik.handleSubmit })
            : null;

        return (
          <Div>
            <Form>
              <FormSection
                title={titleKey}
                FORM={myForm}
                formik={formik}
                lastColumn={lastColumnNode}
                afterRender={afterRenderNode}
              />
              {submitButtonNode}
            </Form>
          </Div>
        );
      }}
    </Formik>
  );
};

export default FormikForm;

import _ from 'lodash';
import { PAYMENT_TYPE } from '$fbusiness/enums/options/paymentType';
import { CREDIT, INVOICE, PAYMENT, REFUND, REFUND_CREDIT } from '$fbusiness/enums/transactionType';
import { toCreditId, toRefundCreditId } from '$fbusiness/models/credit';
import { toRefundId } from '$fbusiness/models/refund';
import { DATE_FORMATS } from '$gbusiness/enums';
import { format } from '$gbusiness/helpers/date';
import intl from '$gintl';
import { accessDisable, getCommissionRate, getInvoice } from '$fbusiness/helpers/util';
import REFUND_STATUS_OPTIONS, { REFUND_STATUS } from '$fbusiness/enums/options/refundStatus';
import { Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { ACCESS } from '$fbusiness/enums/access';

// export const deriveInvoiceNo = (row) => {
//   let str = '';
//   if (row.type === INVOICE || row.type === REFUND) str += row.invoice?.invoiceNumber + ' / ';
//   if (row.type === REFUND) str += toRefundId(row.refund?.id + ' / ');
//   if (row.type === CREDIT) str = toCreditId(row.credit?.id);
//   return str;
// };

export const deriveInfo = (row) => {
  const { paymentGroup, refund, invoice, credit } = row;
  switch (row.type) {
    case INVOICE:
      return <div>{invoice?.charge && <div>{invoice.charge.name}</div>}</div>;
    case PAYMENT:
      if (!paymentGroup) return null;
      return (
        <div>
          <div>{paymentGroup?.type}</div>
          {displayPaymentInfo(paymentGroup)}
        </div>
      );
    case CREDIT:
      return (
        <div className="small">
          {invoice && <div>{invoice.invoiceNumber}</div>}
          <div className="small">{credit?.note}</div>
        </div>
      );
    case REFUND:
      if (!refund) return null;
      return (
        <div className="small">
          {refund?.payment && <div>{refund?.payment}</div>}
          {invoice && <div>{invoice.invoiceNumber}</div>}
          {refund?.note && <div>{refund?.note}</div>}
          {/* {refund?.refundedAt ? (
            <div>Processed: {format(refund.refundedAt)}</div>
          ) : (
            <div>Requested: {format(refund.requestDate)}</div>
          )} */}
        </div>
      );
    case 'REFUND_CREDIT':
      if (!refund || refund?.isCredit) return null;
      return <div className="small">#RC{refund.id}</div>;
    default:
      return null;
  }
};

export function getCommissionAmt(invoice, commission) {
  if (!invoice) return 0;
  const { taxable } = invoice;
  const commRate = getCommissionRate(invoice, commission);
  return Math.round(taxable * commRate) / 100;
}

export const deriveAmount = (row) => {
  const inv = getInvoice(row);
  // if (inv) return inv.total + inv.paymentDiscount || inv.amount || 0;
  if (inv) return inv?.totals?.finalTotal || inv.amount || 0;
  if (row.credit) return row.credit?.totals?.finalTotal || row.amount || 0;
  // if (row.refund) return row.refund?.totals?.finalTotal || row.amount || 0;
  return row.amount || 0;
};

export const deriveOpenBalance = (row) => {
  const inv = getInvoice(row);
  if (inv) return inv?.totals?.finalBalance || inv?.openBalance || 0;
  else if (row.type === REFUND) return row.refund?.balance || 0;
  else if (row.paymentGroup) return row.paymentGroup?.balance * -1;
  else if (row.type.includes(CREDIT)) return row.credit?.balance * -1;
  return 0;
};

export const displayPaymentInfo = (row) => {
  let html: any = [];
  switch (row.type) {
    case PAYMENT_TYPE.CASH:
      if (row.settings?.refNo) html.push(`Ref #${row.settings?.refNo}`);
      break;
    case PAYMENT_TYPE.CHECK:
    case PAYMENT_TYPE.ACH:
      if (row.bank && row.bank.name) html.push(row.bank.name);
      if (row.settings?.checkNo) html.push(`Check #${row.settings?.checkNo}`);
      // if (row.settings?.originalAmt) html.push(`Original Amt: ${currency(row.settings?.originalAmt)}`);
      // else html.push(`Original Amt: ${currency(row.settings?.originalAmt)}`);
      break;
    case PAYMENT_TYPE.CREDIT:
      html.push(row.settings?.cardType);
      if (row.settings?.cardNumber) html.push(`xxx${row.settings?.cardNumber}`);
      break;
    default:
      break;
  }
  return html.join(',');
};

export const deriveInvoiceNo = (row) => {
  const inv = getInvoice(row);
  const type = row.type || INVOICE;
  if (inv) return inv?.invoiceNumber || '';
  if (type === REFUND) {
    if (row.refund?.isCredit) return toCreditId(row.refund?.id);
    return toRefundId(row.refund?.id);
  } else if (type === CREDIT) {
    return toCreditId(row.credit?.id);
  } else if (type === REFUND_CREDIT) {
    return toRefundCreditId(row.refund?.id);
  }
  if (type === PAYMENT) {
    const invoiceNos = (row.paymentGroup.payments || []).map((p) => p.invoiceNumber || '');
    return invoiceNos.join();
  }
  return '';
};

export const deriveDueDate = (row) => {
  const inv = getInvoice(row);
  if (!inv) return '';
  return format(inv.dueDate || '', DATE_FORMATS.DATE_WEEKDAY) || '';
};

export const deriveDeliveryDate = (row) => {
  const inv = getInvoice(row);
  if (!inv) return '';
  return format(inv.order?.deliveryDate || '', DATE_FORMATS.DATE_WEEKDAY) || '';
};

export const creditStatusPicker = (row, onChangeStatus) => {
  const { credit } = row;

  const adjustableStatuses = [REFUND_STATUS.PROCESSED, REFUND_STATUS.VOID];

  return !credit || !adjustableStatuses.includes(credit.status) ? (
    <>{deriveStatus(row)}</>
  ) : (
    <>
      <Select
        variant="outlined"
        className={`xsmall ${accessDisable(ACCESS.ACTION.CREDIT.CHANGE_STATUS)}`}
        value={credit.status}
        onChange={(e) => onChangeStatus(credit.id, e.target.value)}>
        {generateSelectOptions(REFUND_STATUS_OPTIONS, credit.status, true)}
      </Select>
    </>
  );
};

export const deriveStatus = (row) => {
  let status;
  const inv = getInvoice(row);
  if (inv) {
    status = inv.status;
  }
  let type = row.type || INVOICE;
  if (type === REFUND) status = intl(row.refund?.status) || '';
  if (row.refund && !row.refund?.isCredit) {
    type = REFUND;
    status = row.refund?.status;
  }
  if (type === CREDIT) {
    status = 'PROCESSED';
    type = REFUND;
  }
  if (type === PAYMENT && row.paymentGroup?.rejectedAt) {
    return 'Rejected';
  }

  if (status) {
    return intl('INPUT.OPTION.' + type + '_STATUS.' + status) || status;
  } else {
    return '';
  }
};

export const deriveInvoiceField = (row, key) => {
  const inv = getInvoice(row);
  if (!inv) return '';
  return _.get(inv, key);
};

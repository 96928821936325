export default interface InvoiceTypeModel {
  id: number;
  name: string;
  userLevel: any;
  settings?: any;
}

export const deriveRawToInvoiceType = (raw) => {
  if (!raw) return undefined;
  return {
    id: raw.id,
    name: raw.name,
    userLevel: raw.userLevel,
    settings: raw.settings,
  };
};

export const initialInvoiceType = {
  id: 0,
  name: '',
  userLevel: null,
};

import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { AppForm } from '$styles/general';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';

import { INVOICE_TYPE_FORM } from './invoiceTypeForm';
import InvoiceTypeModel from '$fbusiness/models/invoiceType';
import { configs } from '$configs';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';

interface InvoiceTypeDetailsProps {
  invoiceType: InvoiceTypeModel;
  onClose: Function;
}

const InvoiceTypeDetails: React.FC<InvoiceTypeDetailsProps> = ({ invoiceType, onClose }) => {
  const dispatch = useDispatch();
  const roles = useSelector((state: AppModel) => state.factory.roles);
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, dispatch, onClose]);

  if (!invoiceType) return null;
  const { id, userLevel, ...initialValues } = invoiceType;

  const onSubmit = (values) => {
    const newValues = {
      ...values,
    };
    dispatch(
      factoryActions.saveObj(id || 0, newValues, configs.api.invoiceType, factoryActions.fetchInvoiceTypes),
    );
  };

  const invoiceTypeForm = INVOICE_TYPE_FORM(roles);

  const validateForm = (values) => {
    return input.validateError(invoiceTypeForm, values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        userLevelId: userLevel?.id || '3',
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <div className="content">
              <AppForm>
                <FormSection FORM={invoiceTypeForm} formik={formik} />
              </AppForm>
            </div>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SAVE" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default InvoiceTypeDetails;

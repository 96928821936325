import { currency } from '$gbusiness/helpers/util';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { stringToHtml } from '$gbusiness/helpers/input';
import { Tooltip } from '@mui/material';
import { isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

export const STORES_LIST_CONFIG = (storeId): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    defaultSortKey: 'name',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: true,
    pageSize: 5000,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.MEDIUM,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 300,
    rowHeight: 60,
    cellPadding: ` 4px 8px`,
    isStripped: false,
    setRowClass: (row) => (row.id === storeId ? 'selected' : ''),
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

const STORES_LIST = (onSelectStore, currentState): Array<CellModel> => [
  // {
  //   label: 'SCREEN.STORES.COLS.ACCOUNTNO',
  //   value: 'code',
  //   align: 'center',
  //   alwaysShow: true,
  //   width: 150,
  // },
  {
    label: 'SCREEN.STORES.COLS.NAME',
    value: 'name',
    component: (row) => {
      const note = row.settings?.note || '';
      const renderNote = (
        <Tooltip title={<span style={{ fontSize: '14px', color: '#fff' }}>{note}</span>}>
          <div className="pointer ellipsis">"{note}"</div>
        </Tooltip>
      );

      return (
        <div>
          <div className="ellipsis name link pointer" onClick={() => onSelectStore(row)}>
            {row.name}
          </div>
          <div className="subtitle">{stringToHtml(row.formatted)}</div>
          {note && <div className="note">{renderNote}</div>}
        </div>
      );
    },
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.STORES.COLS.BALANCE_OWED',
    value: 'balanceOwed',
    align: 'center',
    isHidden: !isAccessible(ACCESS.MONEY.VIEW, currentState),
    component: (row) => (
      <div className="pointer link" onClick={() => onSelectStore(row, true)}>
        {currency(row.balanceOwed)}
      </div>
    ),
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 100,
  },
];

export default STORES_LIST;

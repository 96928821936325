export const KEYS = {
  FILE: 'FILE',
  ITEM: 'ITEM',
  CATEGORY: 'CATEGORY',
  FACTORY: 'FACTORY',
  MESSAGE: 'MESSAGE',
  EMAIL: 'EMAIL',
};

export const FILE_TYPES = KEYS;

const LIST = Object.keys(KEYS);

export const FILE_TYPE_OPTIONS = LIST.map((item) => ({
  label: `FILE_TYPE.${item}`,
  value: item,
}));

export default FILE_TYPE_OPTIONS;

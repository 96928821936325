import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Select } from '@mui/material';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { deriveRawToSerial } from '$fbusiness/models/serial';
import { Delete, Edit } from '@mui/icons-material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { Flex } from '$gstyles/wrapper';
import PATH from '$business/enums/paths';
import { Link } from '$gcomponents/primitives';
import { accessDisable, accessHide } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';
// import { displayItem } from './serialForm';

export const SERIAL_TABLE_CONFIG = (isItemPage): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.batch.serial,
    deriveToModel: deriveRawToSerial,
    method: 'GET',
    defaultSortKey: 'created_at',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: isItemPage ? 10 : 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 300,
    maxWidth: 1200,
    rowHeight: 24,
    cellPadding: ` 2px 4px`,
    borderColor: 'var(--ion-border-color)',
    responsive: true,
    disableSort: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

export const SERIAL_TABLE = ({
  settings,
  serialOptions,
  onDeleteSerial,
  onClickBatch,
  onChangeStatus,
  onClickCategory = (e) => {},
  onClickStore = (e) => {},
  onClickItem = (e) => {},
  onEditSerial,
}): Array<CellModel> => [
  {
    label: 'SCREEN.SERIAL.COLS.STORE',
    value: 'storeName',
    component: (row) => (
      <span className="link secondary" onClick={() => onClickStore({ id: row.storeId, name: row.storeName })}>
        {row.storeName || ''}
      </span>
    ),
    alwaysShow: true,
    width: 170,
  },
  {
    label: 'SCREEN.SERIAL.COLS.ITEM',
    value: 'item.name',
    component: (row) => (
      <span className="link secondary" onClick={() => onClickItem(row?.item)}>
        {row.item?.name || ''}
      </span>
    ),
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.SERIAL.COLS.CATEGORY',
    value: 'categories',
    component: (row) =>
      (row.cats || []).map((c, i) => (
        <span key={i} className="link secondary" onClick={() => onClickCategory(c)}>
          {c.name || ''}
        </span>
      )),
    alwaysShow: true,
    width: 170,
  },
  {
    label: 'SCREEN.SERIAL.COLS.SKU',
    value: 'item.sku',
    // component: (row) => row.item?.sku || '',
    component: (row) => (
      <Link className="link" route={`${PATH.ITEMS}/${row.item.id}`}>
        {row.item.sku || ''}
      </Link>
    ),
    alwaysShow: true,
    width: 140,
  },
  {
    label: 'SCREEN.SERIAL.COLS.SERIALNO',
    value: 'serial',
    component: (row) => row.serial || '',
    alwaysShow: true,
    width: 180,
  },
  {
    label: 'SCREEN.SERIAL.COLS.NOTE',
    value: 'note',
    component: (row) => <span className="note small">{row.note || ''}</span>,
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.SERIAL.COLS.RECEIVINGNO',
    value: 'batch.receivingNo',
    isHidden: !settings?.batch,
    component: (row) =>
      row.batch && (
        <span className="link" onClick={() => onClickBatch(row.batch, row.item)}>
          {row.batch?.receiveNo || ''}
        </span>
      ),
    alwaysShow: true,
    align: 'center',
    width: 100,
  },
  {
    label: 'SCREEN.SERIAL.COLS.STATUS',
    value: 'status',
    component: (row) => {
      return (
        <Select
          variant="outlined"
          className={`xsmall ${accessDisable(ACCESS.ACTION.SERIAL.EDIT)}`}
          value={row.status}
          onChange={(e) => onChangeStatus(row, e.target.value)}>
          {generateSelectOptions(serialOptions, row.status)}
        </Select>
      );
    },
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.SERIAL.COLS.CREATED_AT',
    value: 'createdAt',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.DATETIME)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: '',
    value: '',
    className: 'action vertical',
    align: 'center',
    alwaysShow: true,
    component: (row) => (
      <Flex>
        <Edit
          className={`pointer ${accessHide(ACCESS.ACTION.SERIAL.EDIT)}`}
          onClick={() => onEditSerial(row)}
        />
        <Delete
          className={`pointer ${accessHide(ACCESS.ACTION.SERIAL.DELETE)}`}
          onClick={() => onDeleteSerial(row.id)}
        />
      </Flex>
    ),
    width: 80,
  },
];

export const SERIAL_TABLE_ITEM = ({
  settings,
  serialOptions,
  onDeleteSerial,
  onClickBatch,
  onChangeStatus,
  onEditSerial,
}): Array<CellModel> => [
  {
    label: 'SCREEN.SERIAL.COLS.RECEIVINGNO',
    value: 'batch.receivingNo',
    isHidden: !settings?.batch,
    component: (row) =>
      row.batch && (
        <span className="link" onClick={() => onClickBatch(row.batch)}>
          {row.batch?.receiveNo || ''}
        </span>
      ),
    alwaysShow: true,
    align: 'center',
    width: 100,
  },
  {
    label: 'SCREEN.SERIAL.COLS.SERIALNO',
    value: 'serial',
    component: (row) => row.serial || '',
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.SERIAL.COLS.STATUS',
    value: 'status',
    component: (row) => {
      return (
        <Select
          variant="outlined"
          className="xsmall"
          value={row.status}
          onChange={(e) => onChangeStatus(row, e.target.value)}>
          {generateSelectOptions(serialOptions, row.status)}
        </Select>
      );
    },
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.SERIAL.COLS.CREATED_AT',
    value: 'createdAt',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.DATETIME)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: '',
    value: '',
    className: 'action vertical',
    align: 'center',
    alwaysShow: true,
    component: (row, actions) => (
      <Flex>
        <Edit
          className={`pointer ${accessHide(ACCESS.ACTION.SERIAL.EDIT)}`}
          onClick={() => onEditSerial(row)}
        />
        <Delete
          className={`pointer ${accessHide(ACCESS.ACTION.SERIAL.DELETE)}`}
          onClick={() => onDeleteSerial(row.id)}
        />
      </Flex>
    ),
    width: 100,
  },
  // {
  //   label: '',
  //   value: '',
  //   className: 'action vertical',
  //   align: 'center',
  //   alwaysShow: true,
  //   component: (row, actions) => (
  //     <Button
  //       className="left no-space"
  //       color="secondary"
  //       fullWidth
  //       onClick={(e) => actions.handlePopoverOpen(e, row)}>
  //       <MoreHorizIcon />
  //     </Button>
  //   ),
  //   width: 80,
  // },
];

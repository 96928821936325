import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonMenuToggle } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import { defaultInvoiceColumns } from './invoicesTable';
import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';

import CurrentStateModel from '$fbusiness/models/currentState';
import { updatedFilter } from '$fbusiness/helpers/util';
import DateFilterModel from '$fbusiness/models/dateFilter';
import { INVOICES } from '$fbusiness/enums/columns';
import { Storefront } from '@mui/icons-material';
import { Split } from '$gstyles';
import { IconButton } from '@mui/material';
import { today } from '$gbusiness/helpers/date';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import InvoiceTableView from '$components/transactionModals/invoiceTableView';
import CreateNewFabs from '$components/transactionModals/createNewFabs';
import PATH from '$business/enums/paths';

interface InvoicesScreenProps {
  history;
  match;
  columns;
  stores;
  dateFilter: DateFilterModel;
  currentState: CurrentStateModel;
  factory: FactoryModel;
}

const defaultFilter = {
  tableTabIndex: 0,
  query: '',
};

const InvoicesScreen: React.FC<InvoicesScreenProps> = ({
  factory,
  history,
  columns,
  match,
  stores,
  currentState,
  dateFilter,
}) => {
  const { params } = match;

  const [store, setStore] = useState<any>(null);
  const storeId = params?.storeId ? parseInt(params.storeId) : 0;

  const {
    location: { state },
  } = history;
  const { user } = currentState;

  const invoiceDefaultFilter: DateFilterModel = {
    dateEnd: today(),
    dateStart: today(),
    datePeriod: DATE_RANGE.TODAY,
  };

  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    ...dateFilter,
    ...invoiceDefaultFilter,
    ...(state && state),
    ...(storeId && { store: stores.find((s) => s.id === storeId) }),
  });

  useEffect(() => {
    if (!store) return;
    setStore(stores.find((s) => s.id === store.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  useEffect(() => {
    const i = parseInt(params.tabIndex || 0);
    setFilter({
      ...filter,
      tableTabIndex: i,
      store: stores.find((s) => s.id === storeId),
      ...(params.tabIndex && {
        dateStart: '',
        dateEnd: today(),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  // const hasProblem = invoice?.status === KEYS.FLAGGED || !!invoice?.revision;
  const invoiceColumns = columns ? columns[INVOICES] || defaultInvoiceColumns : [];

  return (
    <IonPageWrapper>
      <Header
        title="SCREEN.INVOICES.TITLE"
        rightButton={
          <Split>
            <IonMenuToggle menu="end">
              <IconButton>
                <Storefront />
              </IconButton>
            </IonMenuToggle>
          </Split>
        }
      />
      <IonContent>
        {store && <div className="title">{store.name}</div>}
        <InvoiceTableView
          factory={factory}
          stores={stores}
          history={history}
          storeId={storeId}
          currentState={currentState}
          filter={filter}
          invoiceColumns={invoiceColumns}
          updateFilter={updateFilter}
          user={user}
          basePath={PATH.INVOICES}
        />
        {/* <FabButton className="" onClick={onNewInvoice} /> */}
      </IonContent>
      <CreateNewFabs factory={factory} currentState={currentState} store={filter.store} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  stores: state.store.stores,
  factory: state.factory.factory,
  columns: state.localStorage.columns,
  resetOnRoute: true,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(InvoicesScreen));

import PATH from '$business/enums/paths';
import { ACCESS } from '$fbusiness/enums/access';
import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';
import { INVOICE_PAYMENTS } from '$fbusiness/enums/options/paymentType';
import InvoiceModel, { recalculateInvoice } from '$fbusiness/models/invoice';
import InvoicePaymentModel from '$fbusiness/models/invoicePayment';
import { isAccessible } from '$fbusiness/helpers/util';
import { itemText, toNumber } from '$gbusiness/helpers/util';
import ItemActionModel from '$gbusiness/models/itemAction';
import intl from '$gintl';

export interface InvoicePaymentTotalType {
  paymentItems: Array<InvoicePaymentModel>;
  invoice?: InvoiceModel;
  storeCredits: number;
  invoiceTotal: number;
  subtotal: number;
  refundCreditSum?: number;
  invoiceChargeSum?: number;
  taxable: number;
  tax: number;
  balance: number;
  payments: number;
  credits: number;
  refunds: number;
  discounts: number;
  otherDiscount: number;
  totalDiscount: number;
  originalTotal: number;
}

export function buildInvoiceItemAction({
  factory,
  history,
  currentState,
  stores,
  onDownloadInvoice = (e) => {},
  onEmailInvoice = (e) => {},
  onAddCredit = (e) => {},
  onAddCharge = (e, r = false) => {},
  onEditCharge = (e) => {},
  onCreateRefund = (e, f) => {},
}): Array<ItemActionModel> {
  return [
    ...(factory?.settings?.createInvoice
      ? [
          {
            labelText: itemText('CREATE_NEW', 'INVOICE'),
            eventName: 'create',
            hide: (row) => !!row?.charge,
            disabled: () => !isAccessible(ACCESS.ACTION.INVOICE.CREATE, currentState),
            onClick: (row) => history.push(`${PATH.INVOICE}/0/${row.id}`),
          },
          {
            createLabel: (row) => itemText('edit', row?.charge ? row.charge.name : 'INVOICE'),
            eventName: 'edit',
            disabled: (row) =>
              row?.status === INVOICE_STATUS.REFUNDED ||
              row?.status === INVOICE_STATUS.CANCELLED ||
              !isAccessible(ACCESS.ACTION.INVOICE.EDIT, currentState),
            onClick: (row) => {
              if (row?.charge) onEditCharge(row);
              else history.push(`${PATH.INVOICE}/1/${row.id}`);
            },
          },
        ]
      : []),
    ...(factory?.settings?.refundVoucher
      ? [
          {
            labelText: intl('SCREEN.INVOICES.ACTIONS.ADD_REFUND'),
            eventName: 'refund',
            hide: (row) => !!row?.charge,
            disabled: () => !isAccessible(ACCESS.ACTION.REFUND.CREATE, currentState),
            onClick: (row) => onCreateRefund(row, true),
          },
          // {
          //   labelText: intl('SCREEN.INVOICES.ACTIONS.CONVERT_REFUND'),
          //   eventName: 'refund',
          //   disabled: (row) => {
          //     if (row?.refund) return true;
          //     return factory.settings?.paidRefundOnly
          //       ? row?.balance > 0 || row?.status !== INVOICE_STATUS.PAID
          //       : row?.balance === row?.total;
          //   },
          //   onClick: (row) => onCreateRefund(row, true),
          //   hide: (row) => {
          //     return row?.status === INVOICE_STATUS.CANCELLED;
          //   },
          // },
        ]
      : []),
    ...(factory?.settings?.invoiceEmail
      ? [
          {
            labelText: intl('SCREEN.FACTORY.FEATURES.EMAIL_INVOICE'),
            hide: (row) => !!row?.charge,
            disabled: () => !isAccessible(ACCESS.ACTION.INVOICE.EMAIL, currentState),
            createLabel: (row) => itemText('EMAIL', row?.charge ? row.charge.name : 'INVOICE'),
            eventName: 'email',
            onClick: (row) => onEmailInvoice(row),
          },
        ]
      : []),
    {
      labelText: intl('BUTTON.DOWNLOAD'),
      eventName: 'download',
      hide: (row) => !!row?.charge,
      disabled: () => !isAccessible(ACCESS.ACTION.INVOICE.DOWNLOAD, currentState),
      onClick: (row) => onDownloadInvoice(row),
    },
    {
      createLabel: (row) => itemText('ADD_NEW', 'CHARGE'),
      eventName: 'charge',
      disabled: () => !isAccessible(ACCESS.ACTION.CHARGE.CREATE, currentState),
      onClick: (row) => onAddCharge(row),
    },
    {
      createLabel: (row) => itemText('ADD_NEW', 'INVOICE_CHARGE'),
      eventName: 'invoiceCharge',
      hide: (row) => !!row?.charge,
      disabled: () => !isAccessible(ACCESS.ACTION.INVOICE.CHARGE, currentState),
      onClick: (row) => onAddCharge(row, true),
    },
    ...(factory?.settings?.customCredit && isAccessible(ACCESS.ACTION.CREDIT.CREATE, currentState)
      ? [
          {
            labelText: itemText('ADD', 'CREDITS'),
            hide: (row) => !!row?.charge,
            createLabel: (row) => intl('SCREEN.INVOICES.ACTIONS.ADD_CREDITS', { store: row?.store?.name }),
            eventName: 'credit',
            onClick: onAddCredit,
          },
        ]
      : []),
  ];
}

export function calculateTotal(
  originalInvoice,
  store,
  payments,
  skipItems = false,
  skipInvoice = false,
): InvoicePaymentTotalType {
  // const earlyDiscount = getEarlyDiscountAmount(originalInvoice);
  const [paymentTotal, discountTotal, creditsTotal] = payments.reduce(
    (acc, p) => {
      if (p.rejectedAt) return acc;
      if (p.type === INVOICE_PAYMENTS.DISCOUNT) acc[1] += p.amount;
      else if (p.type === INVOICE_PAYMENTS.CREDITS) acc[2] += p.amount;
      else acc[0] += p.amount || 0;
      return acc;
    },
    [0, 0, 0],
  );

  const adjustedInvoice = recalculateInvoice(
    {
      ...originalInvoice,
      otherDiscount: originalInvoice.otherDiscount, // + earlyDiscount,
      paymentDiscount: discountTotal,
    },
    { fixedQty: true, skipItems },
  );

  const retroInvoice =
    discountTotal > 0
      ? recalculateInvoice(
          {
            ...originalInvoice,
            paymentDiscount: 0,
          },
          { fixedQty: true, skipItems },
        )
      : originalInvoice;

  const { tax, total, itemTaxable, taxable, refundAmount, totalDiscount } = adjustedInvoice;
  const refundCreditSum = originalInvoice?.totals?.refundCreditSum || 0;
  const invoiceChargeSum = originalInvoice?.totals?.invoiceChargeSum || 0;
  const finalBalance =
    toNumber(total + invoiceChargeSum - paymentTotal - discountTotal - creditsTotal - refundAmount) -
    refundCreditSum;
  const finalTotal = total + invoiceChargeSum;

  const final = {
    ...(!skipInvoice && { invoice: originalInvoice }),
    paymentItems: payments,
    storeCredits: store?.credits || 0,
    // balance: toNumber(total - paymentTotal - discountTotal - creditsTotal - refundAmount),
    payments: paymentTotal,
    discounts: discountTotal,
    paymentDiscount: discountTotal,
    otherDiscount: originalInvoice.otherDiscount,
    totalDiscount,
    refunds: refundAmount,
    credits: creditsTotal,
    invoiceTotal: total,
    subtotal: itemTaxable,
    taxable,
    tax,
    originalTotal: retroInvoice.total,
    balance: finalBalance,
    finalTotal,
    refundCreditSum,
    invoiceChargeSum,
  };

  return final;
}

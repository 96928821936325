import { deriveRawToRegion } from '$fbusiness/models/region';

export default interface UserModel {
  userId?: number;
  firstName: string;
  lastName: string;
  email: string;
  name?: string;
  userLevel?: number;
  other?: any;
  settings?: any;
}

export const emptyUser: UserModel = {
  userId: 0,
  firstName: '',
  lastName: '',
  name: '',
  email: '',
};

export interface LoginModel {
  email: string;
  password: string;
  remember: boolean;
}

export interface RegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  secretKey?: string;
}

export const defaultUser = {
  userId: 0,
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  userLevel: 1,
};

export const getFullName = (user) => {
  if (!user) return '';
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const getMobile = (user) => {
  if (user?.other) return '';
  return user?.other?.mobile || '';
};

export const deriveRawToUser = (raw) => {
  if (!raw) return defaultUser;

  const { firstName, lastName, email, level, userId, id, name, phone, mobile, token, other, settings } = raw;

  return {
    userId: userId || id,
    firstName,
    lastName,
    email,
    ...(level && { userLevel: level.id }),
    ...(name && { name }),
    other: {
      ...other,
      isStoreUser: raw.storeId > 0,
      mobile: phone || mobile || '',
      token: token || '',
      ...(raw.commission && { commission: raw.commission }),
      ...(raw.factoryId && { factoryId: raw.factoryId }),
      ...(raw.isSalesman && { isSalesman: raw.isSalesman }),
      ...(raw.regions && { regions: raw.regions.map(deriveRawToRegion) }),
    },
    ...(settings && { settings }),
  };
};

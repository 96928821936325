import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import AddIcon from '@mui/icons-material/Add';
import Tree, { RenderItemParams } from '@atlaskit/tree';

import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import intl from '$gintl';
import { Desktop, Div, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { categoryActions } from '$fbusiness/redux/category';
import { initialValue } from '$fbusiness/models/category';
import { getTreeNode } from '$fbusiness/models/tree';

import ItemsFilter from '../../inventoryScreen/items/filter';
import CategoryModal from '../categoryModal';
import CategoryTreeItem from './treeItem';
import { IonPageWrapper } from './styles';
import { reassignRank } from './utils';
import { dialog } from '$gcomponents/reusables';
import { accessDisable, accessHide, isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface CategoriesScreenProps {
  onHydrate;
  onDehydrate;
  categories;
  deleteCategory;
  saveCategory;
  toggleExpand;
  toggleProp;
  factory;
  currentState;
  tree;
  expands;
  alphabetize;
}

const CategoriesScreen: React.FC<CategoriesScreenProps> = ({
  currentState,
  categories,
  deleteCategory,
  saveCategory,
  toggleProp,
  toggleExpand,
  onHydrate,
  factory,
  tree,
  expands,
  alphabetize,
}) => {
  const [category, setCategory] = useState<any>(null);
  const [draggableId, setDraggableId] = useState<any>(0);
  const [selections, setSelections] = useState<any>({});
  const [filteredItems, setFilteredItems] = useState<any>(null);

  const onCloseModal = () => {
    setCategory(null);
  };

  const removeCategory = async (id) => {
    await deleteCategory(id);
    onHydrate(id);
  };

  const editCategory = (id, parentId) => {
    if (!id) {
      setCategory({
        ...initialValue,
        ...(parentId ? { parentId } : {}),
      });
      return;
    }
    const category = getTreeNode(categories, id);
    setCategory(category);
  };

  const onSearch = ({ query }) => {
    if (query.length < 2) {
      setFilteredItems(null);
      return;
    }
    const result = {};
    for (const i in tree.items) {
      const data = tree.items[i].data;
      if (data.name.toLowerCase().includes(query.toLowerCase())) result[data.id] = true;
    }
    setFilteredItems(result);
  };

  const onDragEnd = async (source, destination) => {
    if (!source || !destination) return;
    const { parentId } = destination;
    const parentChanged = source.parentId !== parentId;
    if (!parentChanged && source.index === destination.index) return;
    const parent = getTreeNode(categories, parentId);
    const siblings = parent.children || categories;
    const newRank = reassignRank(siblings, source.index, destination.index, parentChanged);
    await saveCategory(draggableId, { rank: newRank, parentId: destination.parentId }, true);
    await onHydrate();
  };

  const toggleCheck = (id) => {
    if (selections[id]) setSelections({ ...selections, [id]: undefined });
    else setSelections({ ...selections, [id]: true });
  };

  const toggleHide = async (id, hide) => {
    await toggleProp(id, { hide });
    await onHydrate();
  };

  const onAlphabetize = async () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.WARNING_PROCEED',
      cssClass: 'large',
      onPress: async () => {
        await alphabetize();
        await onHydrate();
      },
    });
  };

  const buttonComponent = (
    <Flex justifyContent="space-between">
      <Button
        onClick={() => editCategory(0, 0)}
        variant="contained"
        size="medium"
        color="primary"
        className={accessDisable(ACCESS.ACTION.CATEGORY.CREATE)}
        startIcon={
          <Desktop alignItems="center" className="flex">
            <AddIcon />
          </Desktop>
        }>
        {intl('ITEM.NEW', { item: 'Category' })}
      </Button>
      <Button
        onClick={onAlphabetize}
        className={`light filled ${accessHide(ACCESS.ACTION.CATEGORY.ALPHABETIZE)}`}>
        Alphabetize
      </Button>
    </Flex>
  );

  const renderItem = ({ item, provided, depth }: RenderItemParams) => {
    return (
      <div
        className="draggable-row"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}>
        <CategoryTreeItem
          isFilteredOut={filteredItems === null ? null : !filteredItems[item.id]}
          treeItem={item}
          toggleExpand={toggleExpand}
          toggleHide={toggleHide}
          removeCategory={removeCategory}
          addSubcategory={(id) => editCategory(0, id)}
          editCategory={editCategory}
          isChecked={!!selections[item.id]}
          isExpanded={expands[item.id] !== false}
          toggleCheck={toggleCheck}
          level={depth}
        />
      </div>
    );
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.CATEGORIES.TITLE" />
      <IonContent>
        <Div height="100%" overflow="auto">
          <Div padding={`${SPACE.XLARGE} ${SPACE.LARGE} 0 ${SPACE.LARGE}`}>
            <ItemsFilter
              currentState={currentState}
              onSearch={onSearch}
              addButton={buttonComponent}
              factory={factory}
              excludeImport
            />
          </Div>
          <Tree
            tree={tree}
            renderItem={renderItem}
            onDragStart={(id) => setDraggableId(id)}
            onDragEnd={onDragEnd}
            isDragEnabled={isAccessible(ACCESS.ACTION.CATEGORY.RANK, currentState)}
          />
        </Div>
        <CategoryModal
          factory={factory}
          category={category}
          categories={categories}
          show={!!category}
          onClose={onCloseModal}
        />
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  categories: state.category.categories,
  tree: state.category.tree,
  expands: state.category.expands,
});

const mapDispatchToProps = {
  onHydrate: categoryActions.fetchCategories,
  onDehydrate: categoryActions.dehydrate,
  deleteCategory: categoryActions.deleteCategory,
  alphabetize: categoryActions.alphabetize,
  saveCategory: categoryActions.saveCategory,
  toggleExpand: categoryActions.toggleExpand,
  toggleProp: categoryActions.toggleProp,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CategoriesScreen));

export default interface DistributionItemModel {
  id: number;
  itemId: number;
  quantity: number;
  warehouseId: number;
  warehouse: string;
  short: string;
}

export const deriveRawToDistributionItem = (raw) => {
  return {
    id: raw.id,
    itemId: raw.itemId,
    quantity: raw.quantity,
    warehouseId: raw.warehouseId,
    warehouse: raw.warehouse,
    short: raw.short,
  };
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';
import { input } from '$gbusiness/helpers';
import { IonPageWrapper } from './styles';
import { factoryActions } from '$fbusiness/redux/factory';
import { TableView2 } from '$gcomponents/widgets';
import { COMMRULE_TABLE, COMMRULE_TABLE_CONFIG } from './commRuleTable';
import { initialCommRule } from '$fbusiness/models/commRule';
import CommRuleEditModal from './commRuleModal';
import { FormSection } from '$gcomponents/reusables';
import { COMM_PAY_SETTING_FORM } from './commRuleForm';
import { Formik } from 'formik';
import { PageWrapper } from '$gstyles/wrapper';
import { PAYMENT_FREQ } from '$fbusiness/enums/options/paymentFreq';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import FabButton from '$fcomponents/fabButton';
import { accessDisable, accessHide } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface CommRulesScreenProps {
  factory;
  onHydrate;
  saveFactory;
  deleteCommRule;
  currentState;
}

const CommRulesScreen: React.FC<CommRulesScreenProps> = ({
  factory,
  saveFactory,
  deleteCommRule,
  currentState,
}) => {
  const [commRule, setCommRule] = useState<any>(null);
  const { commRules, settings, factoryId } = factory;
  const initialValues = settings?.commPay || {
    freq: PAYMENT_FREQ.SEMI_MONTHLY,
    startDay: 15,
    endDay: 30,
    weekday: 5,
    lag: 2,
    commissionLag: 6,
  };

  const onAddCommRule = () => {
    setCommRule(initialCommRule);
  };
  const onRowClick = (row) => {
    setCommRule(row);
  };
  const onDeleteItem = (id) => {
    deleteCommRule(id);
  };

  const onSubmit = (values) => {
    saveFactory(
      factoryId,
      {
        settings: {
          ...settings,
          commPay: values,
        },
      },
      true,
    );
  };

  const validateForm = (values) => input.validateError(COMM_PAY_SETTING_FORM(values), values);

  return (
    <IonPageWrapper>
      <Header title="SCREEN.COMMRULES.TITLE" />
      <IonContent>
        <TableView2
          className="table-view"
          data={commRules}
          tableConfig={COMMRULE_TABLE_CONFIG}
          TABLE={COMMRULE_TABLE(onRowClick, onDeleteItem)}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...(factory.settings?.commPay || {}),
            ...initialValues,
          }}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <PageWrapper maxWidth="800px">
              <FormSection
                title="SCREEN.COMMRULES.TITLE_PAYOUT"
                FORM={COMM_PAY_SETTING_FORM(formik.values)}
                formik={formik}
              />
              <Button
                className={accessDisable(ACCESS.ACTION.SETTING.PAYOUT.EDIT)}
                onClick={formik.handleSubmit}>
                {intl('ITEM.SAVE', { item: 'Payout Settings' })}
              </Button>
            </PageWrapper>
          )}
        </Formik>
      </IonContent>
      <FabButton
        onClick={onAddCommRule}
        className={accessHide(ACCESS.ACTION.SETTING.COMMISSION_RULE.CREATE)}
      />
      <CommRuleEditModal
        currentState={currentState}
        show={!!commRule}
        commRule={commRule}
        onClose={() => setCommRule(null)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchCommRules(),
  saveFactory: factoryActions.saveFactory,
  updateCommRule: factoryActions.saveCommRule,
  deleteCommRule: factoryActions.deleteCommRule,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CommRulesScreen));

import React from 'react';
import { ModalWrapper } from './styles';
import { itemText } from '$gbusiness/helpers/util';
import InvoiceTypeDetails from './invoiceTypeDetails';
import InvoiceTypeModel from '$fbusiness/models/invoiceType';

interface InvoiceTypeEditModalProps {
  invoiceType: InvoiceTypeModel;
  onClose: Function;
}

const InvoiceTypeEditModal: React.FC<InvoiceTypeEditModalProps> = ({ invoiceType, onClose }) => {
  const titleText = itemText(invoiceType?.id ? 'EDIT' : 'CREATE', 'INVOICE_TYPE');

  return (
    <ModalWrapper show={!!invoiceType} onClose={() => onClose()} titleText={titleText} useCustom>
      {invoiceType && <InvoiceTypeDetails invoiceType={invoiceType} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default InvoiceTypeEditModal;

import { toLocal } from '$gbusiness/helpers/date';
import CategoryModel from './category';
import DepartmentModel from './department';
import DistributionItemModel, { deriveRawToDistributionItem } from './distributionItem';
import FileModel, { deriveRawToFile, initialFile } from './file';
import ModifierGroupModel, { deriveRawToModifierGroup } from './modifierGroup';
import TaxModel, { deriveRawToTax } from './tax';

export default interface ItemModel {
  id: number;
  name: string;
  description?: string;
  categories?: Array<CategoryModel>;
  categoryIds?: Array<number>;
  depts?: Array<DepartmentModel>;
  deptIds?: Array<number>;
  rawCost: number;
  wholesalePrice: number;
  image?: FileModel;
  retailPrice: number;
  rank?: number;
  quantity?: number;
  quantitySum?: number;
  outOfStock?: boolean;
  files?: Array<FileModel>;
  barcode?: string;
  sku?: string;
  upc: string;
  vendorId?: number;
  price?: number;
  taxId?: number;
  taxPercent?: number;
  tax?: TaxModel;
  hide: boolean;
  updated?: boolean;
  createdAt?: string;
  updatedAt?: string;
  modifierGroups: Array<ModifierGroupModel>;
  ebrochure?: boolean;
  distributions?: Array<DistributionItemModel>;
  settings?: {
    promo?: boolean;
    promoImage?: string;
    packing?: string;
    skid?: string;
    lotNo?: string;
    binNo?: string;
    requireSerial?: boolean;
    disableDiscount: boolean;
  };
}

export const getDepartments = (categories) => {
  if (!categories) return [[], []];
  let deptSet = {};
  let deptIds: Array<Number> = [];
  let depts: Array<DepartmentModel> = [];
  for (const cat of categories) {
    if (!cat.departments) continue;
    for (const dept of cat.departments) {
      deptSet[dept.id] = dept.name;
    }
  }
  for (const key in deptSet) {
    const id = parseInt(key);
    deptIds.push(id);
    depts.push({ id, name: deptSet[id] });
  }

  return [deptIds, depts];
};

export const deriveRawToItem = (raw) => {
  const [deptIds, depts] = getDepartments(raw.categories);
  return {
    id: raw.id,
    name: raw.name,
    categories: (raw.categories || []).map((d) => ({ id: d.id, name: d.name, breadcrumbs: d.breadcrumbs })),
    categoryIds: (raw.categories || []).map((d) => d.id),
    depts,
    deptIds,
    outOfStock: raw.quantity !== null && raw.quantity <= 0 ? true : false,
    description: raw.description,
    image: raw.imageUri === 'DELETED' ? initialFile : getMainImage(raw.files),
    files: (raw.files || []).map(deriveRawToFile),
    rank: raw.rank || 0,
    rawCost: raw.rawCost,
    wholesalePrice: raw.wholesalePrice,
    retailPrice: raw.retailPrice,
    quantity: raw.quantity !== null && raw.quantity <= 0 ? 0 : raw.quantity,
    quantitySum: raw.quantitySum || 0,
    barcode: raw.barcode,
    sku: raw.sku,
    upc: raw.upc,
    hide: raw.hide,
    ebrochure: raw.ebrochure,
    taxId: raw.tax?.id || 0,
    taxPercent: getTaxPercent(raw.tax),
    vendorId: raw.vendor?.id || 0,
    ...(raw.tax && { tax: deriveRawToTax(raw.tax) }),
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt),
    modifierGroups: (raw.modifierGroups || []).map((g) => deriveRawToModifierGroup(g)),
    ...(raw.settings && { settings: Array.isArray(raw.settings) ? {} : raw.settings }),
    ...(raw.distributions && {
      distributions: raw.distributions.map(deriveRawToDistributionItem),
    }),
  };
};

export const getTaxPercent = (tax) => {
  if (!tax) return 0;
  return tax.unit === 'PERCENT' ? tax.amount : 0;
};

export const getMainImageAttr = (files, string) => {
  const file = getMainImage(files);
  return file ? file[string] : null;
};

export const getMainImage = (files): FileModel | undefined => {
  if (!files || !files.length) return initialFile;
  return files.find((f) => f.isMain) || initialFile;
};

export const initialItem = {
  id: 0,
  name: '',
  categoryIds: [],
  deptIds: [],
  description: '',
  rawCost: 0,
  wholesalePrice: 0,
  retailPrice: 0,
  hide: false,
  upc: '',
  modifierGroups: [],
};

import React, { useState } from 'react';
import intl from '$intl';

import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';
import { dialog } from '$gcomponents/reusables';
import { TableView2 } from '$gcomponents/widgets';
import {
  invoiceExportSettings,
  INVOICE_TABLE,
  INVOICE_TABLE_CONFIG,
} from '../../screens/invoicesScreen/invoicesTable';
import { INVOICES } from '$fbusiness/enums/columns';
import ItemActionModel from '$gbusiness/models/itemAction';
import { buildInvoiceItemAction } from '../../screens/invoicesScreen/utils';
import { configs } from '$configs';
import { currency, download, replaceAll } from '$gbusiness/helpers/util';
import { invoiceActions } from '$fbusiness/redux/invoice';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { INVOICE_PAYMENTS_TABLE_MIN } from '$fscreens/accountScreen/invoicesScreen/innerTable';
import { useDispatch } from 'react-redux';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';
import UserModel from '$gbusiness/models/user';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import { Flex } from '$gstyles/wrapper';
import { ExportContainer } from '../../screens/storeScreens/storesScreen/styles';
import ExportButton from '$gcomponents/reusables/exportButton';
import TransactionModals from '.';
import CurrentStateModel from '$fbusiness/models/currentState';
import { TRANSACTIONS_TABLE_CONFIG } from '../../screens/transactionsScreen/transactionsTable';
import { TableSummarySection, Wrapper } from './styles';
import TRANSACTION_FILTER from './transactionFilter';
import { convertPaymentToGroup } from '../../screens/invoicesScreen/paymentModal/utils';
import OptionModel from '$gbusiness/models/option';
import PATH from '$business/enums/paths';
import { COLORS } from '$gbusiness/enums';

const TTABS: Array<OptionModel> = [
  { label: 'SCREEN.INVOICES.TABS.ALL', value: '' },
  { label: 'SCREEN.INVOICES.TABS.OPEN', value: 'OPEN' },
  { label: 'SCREEN.INVOICES.TABS.PAID', value: 'PAID' },
  { label: 'SCREEN.INVOICES.TABS.VOID_PAYMENT', value: 'VOID_PAYMENT' },
  { label: 'SCREEN.INVOICES.TABS.CANCELLED', value: 'CANCELLED' },
  { label: 'SCREEN.INVOICES.TABS.REFUNDED', value: 'REFUNDED' },
];

const MODES = {
  INVOICE: 'INVOICE',
  BALANCE: 'BALANCE',
};

interface InvoiceTableViewProps {
  factory: FactoryModel;
  stores: Array<StoreModel>;
  history: any;
  currentState: CurrentStateModel;
  storeId?: number;
  filter: any;
  invoiceColumns?: any;
  updateFilter: Function;
  mode?: string;
  user?: UserModel;
  basePath?: string;
  isTransaction?: boolean;
}

const InvoiceTableView: React.FC<InvoiceTableViewProps> = ({
  factory,
  stores,
  history,
  storeId,
  currentState,
  filter,
  invoiceColumns,
  updateFilter,
  mode = MODES.INVOICE,
  user,
  basePath = PATH.INVOICES,
  isTransaction = false,
}) => {
  const childRef = React.useRef<any>();
  const dispatch = useDispatch();
  const [openBalance, setOpenBalance] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [paymentGroup, setPaymentGroup] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totals, setTotals] = useState<any>([0, 0]);
  const { settings, invoiceTypes } = factory;

  const onDownloadInvoice = async (inv) => {
    const { id, uuid } = inv;
    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.invoice.download}/${uuid}`;
    download(url, 'invoice_' + id + '.pdf');
    // await downloadInvoice(uuid);
  };

  const onEmailInvoice = async (inv) => {
    const { id, store } = inv;
    const invoiceEmail = store?.settings?.invoiceEmail || '';
    if (!invoiceEmail) {
      dialog.alert({
        title: 'MESSAGE.SORRY',
        message: 'MESSAGE.INVOICE_EMAIL_MISSING',
        key: { name: store?.name || '' },
      });
      return;
    }
    const trimmedEmail = replaceAll(invoiceEmail, { '\n': '', ' ': '' });
    dispatch(invoiceActions.emailInvoice(id, trimmedEmail));
  };

  const onViewRefund = (refund) => {
    childRef.current?.showRefund(refund);
    setIsModalOpen(true);
  };

  const onViewInvoice = (row) => {
    childRef.current?.showInvoice(row);
    setIsModalOpen(true);
  };

  const onAddCredit = (row) => {
    childRef.current?.showCredit(row.storeId);
    setIsModalOpen(true);
  };

  const onAddCharge = (row, isInvoiceCharge = false) => {
    childRef.current?.showStatementCharge({ ...row, isInvoiceCharge }, row.store, true);
    setIsModalOpen(true);
  };

  const onEditCharge = (row) => {
    childRef.current?.showStatementCharge(row);
    setIsModalOpen(true);
  };

  const onViewPaymentGroup = (row, store) => {
    setPaymentGroup(row);
    childRef.current?.showPayment(null, store);
    setIsModalOpen(true);
  };

  const onReceivePayment = async (row) => {
    childRef.current?.showPayment(row, row.store);
    setIsModalOpen(true);
  };

  const onChangeRefundStatus = async (id, value) => {
    await dispatch(invoiceActions.updateRefundStatus(id, value)).then((result) => {
      updateFilter({});
    });
  };

  const onChangePaymentGroup = (row) => {
    setPaymentGroup(convertPaymentToGroup(row));
  };

  const onClickPaymentGroup = async (row) => {
    setPaymentGroup(convertPaymentToGroup(row));
    await dispatch(invoiceActions.fetchInvoice(row.invoiceId)).then((result) => {
      childRef.current?.showPayment(result);
    });
  };

  const onCloseModals = () => {
    setPaymentGroup(null);
    setIsModalOpen(false);
  };

  const onChangeStatus = (row, status) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message:
        status === INVOICE_STATUS.VOID_PAYMENT
          ? 'MESSAGE.VOID_WARNING'
          : status === INVOICE_STATUS.DELETE
          ? 'MESSAGE.DELETE_INVOICE_WARNING'
          : 'MESSAGE.INVOICE_STATUS_WARNING',
      key: { status: intl('INPUT.OPTION.INVOICE_STATUS.' + status) },
      cssClass: 'large',
      onPress: async () => {
        if (status === INVOICE_STATUS.VOID_PAYMENT) {
          await dispatch(invoiceActions.voidInvoice(row.id));
        } else {
          await dispatch(
            invoiceActions.updateInvoice({
              id: row.id,
              status,
            }),
          );
        }
        updateFilter({});
      },
    });
  };

  const onTableLoad = (a, b, c, d) => {
    if (mode !== MODES.INVOICE) {
      if (!c.store) return;
      // const totals = d.reduce(
      //   (acc, row) => {
      //     acc[0] += deriveAmount(row);
      //     acc[1] += deriveOpenBalance(row);
      //     return acc;
      //   },
      //   [0, 0],
      // );
      setTotals([c.totals.total, c.totals.balance]);
      setCreditAmount(c.store.credits);
    } else {
      setOpenBalance(c.openBalance || 0);
      setCreditAmount(c.credits || 0);
      return;
    }
  };

  const onClickStore = (values, value) => {
    const id = value?.id || values?.id;
    history.push(basePath + '/' + id);
  };

  const onChangeStore = (values, value) => {
    const id = value?.id || values?.id;
    if (!id) {
      history.push(basePath);
      return;
    }
    history.push(basePath + '/' + id);
  };

  const onCreateRefund = (row, shouldConvert = false) => {
    // if (!shouldConvert) {
    //   navRefund(row);
    //   return;
    // }
    dialog.confirm({
      message: 'MESSAGE.CREDIT_OR_REFUND',
      buttons: [
        {
          text: intl('COMMON.REFUND'),
          cssClass: COLORS.SUCCESS,
          handler: () => navRefund(row, false, shouldConvert),
        },
        {
          text: intl('COMMON.CREDIT'),
          cssClass: COLORS.WARNING,
          handler: () => navRefund(row, true, shouldConvert),
        },
      ],
    });
  };

  const onEditRefund = (row) => {
    if (row.isCredit && row.simple) {
      childRef.current?.showCredit(row.storeId, row);
      return;
    }
    // dialog.alert({ message: 'Unable to edit at this time' });
    // return;

    history.push(`${PATH.EDIT_REFUND}/${row.id}`);
  };

  const onDeleteRefund = (row) => {
    dialog.confirm({
      message: row.isCredit ? 'MESSAGE.DELETE_CREDIT_WARNING' : 'MESSAGE.DELETE_REFUND_WARNING',
      onPress: () => {
        dispatch(invoiceActions.deleteRefund(row.id)).then(() => {
          updateFilter({});
        });
      },
    });
  };

  const onRejectPayment = (row) => {
    dispatch(invoiceActions.getPaymentInvoices(row.paymentGroupId)).then((result) => {
      const invoiceList = !result ? '' : `(${result.join(', ')})`;
      dialog.confirm({
        message: 'MESSAGE.REJECT_PAYMENT_WARINIG',
        key: { invoices: invoiceList },
        onPress: () => {
          dispatch(invoiceActions.rejectPayment(row.paymentGroupId)).then((result) => {
            updateFilter({});
          });
        },
      });
    });
  };

  // const onDeleteCharge = (row) => {
  //   dialog.confirm({
  //     message: 'MESSAGE.DELETE_CHARGE_WARNING',
  //     onPress: () => {
  //       dispatch(invoiceActions.voidInvoice(row.id)).then(() => {
  //         updateFilter({});
  //       });
  //     },
  //   });
  //   childRef.current?.showCredit(row.storeId);
  //   setIsModalOpen(true);
  // };

  const navRefund = (row, isCredit = false, shouldConvert = false) => {
    const path = isCredit ? PATH.CREATE_CREDIT : PATH.CREATE_REFUND;
    if (shouldConvert) history.push(`${path}/${row?.id}/0/1`);
    else history.push(`${path}/${row.id}`);
  };

  const itemActions: Array<ItemActionModel> = buildInvoiceItemAction({
    factory,
    currentState,
    history,
    stores,
    onDownloadInvoice,
    onEmailInvoice,
    onAddCredit,
    onEditCharge,
    onCreateRefund,
    onAddCharge,
  });

  const finalTabs = [
    ...TTABS,
    ...(invoiceTypes || [])
      .filter(
        (invoiceType) =>
          invoiceType?.userLevel?.id >=
          (currentState?.user?.userLevel === 0 ? 0 : currentState?.user?.userLevel || 5),
      )
      .map((invoiceType, i) => ({
        label: 'SCREEN.INVOICES.TABS',
        labelText: invoiceType?.name || '',
        value: invoiceType?.id,
      })),
  ];

  const tableTabs = {
    TABS: finalTabs,
    index: filter?.tableTabIndex,
    onChange: (newTableIndex) => {
      const newStatus = finalTabs[newTableIndex].value;
      if (newStatus === 'REFUNDED') {
        history.push(PATH.REFUNDS + '/1');
        return;
      }

      updateFilter({
        tableTabIndex: newTableIndex,
        ...(typeof newStatus === 'number'
          ? { invoiceTypeId: newStatus, status: undefined }
          : { status: newStatus, invoiceTypeId: undefined }),
      });
    },
    color: 'primary' as 'primary' | 'secondary',
    itemWidth: '60px',
  };

  const filterParam = {
    ...filter,
    ...(filter.store && { storeId: filter.store.id }),
    ...(storeId && { storeId }),
    tableTabIndex: undefined,
    customFilter: undefined,
    ...(filter.customFilter && { status: filter.customFilter }),
    store: undefined,
  };

  const transactionFilter = TRANSACTION_FILTER();
  const deleteInvoice = false; // user?.userLevel === 0 || (settings?.deleteInvoice && (user?.userLevel || 2) <= 1);
  return (
    <Wrapper>
      {mode === MODES.INVOICE ? (
        <TableView2
          filter={filterParam}
          className="invoices-table"
          tableConfig={INVOICE_TABLE_CONFIG}
          columns={invoiceColumns}
          columnKey={INVOICES}
          pauseAutoRefresh={isModalOpen}
          itemActions={itemActions}
          INNER_TABLE={INVOICE_PAYMENTS_TABLE_MIN(onRejectPayment, onClickPaymentGroup)}
          onSelection={() => {}}
          onTableLoad={onTableLoad}
          TABLE={INVOICE_TABLE({
            onViewInvoice,
            onChangeStatus,
            onChangeRefundStatus,
            onReceivePayment,
            onClickStore,
            isPickupOnly: settings?.pickupOnly,
            isEcommerce: factory?.type === FACTORY_TYPE.ECOMMERCE,
            deleteInvoice,
            currentState,
            onDeleteRefund,
            onAddCharge,
          })}
          tabs={tableTabs}>
          <FilterSection title={null}>
            <ReportFilter
              stores={stores}
              parentFilter={filter}
              onSearch={updateFilter}
              onChangeStore={onChangeStore}
              defaultRange={DATE_RANGE.TODAY}
              placeholder="INPUT.PLACEHOLDER.INVOICE_SEARCH"
            />
          </FilterSection>
          <TableSummarySection>
            {filter.tableTabIndex <= 1 && (
              <Flex justifyContent="flex-start" className="summary">
                <div>
                  <span className="label">{intl('SCREEN.STORES.TOTAL_OPEN_BALANCE')}:</span>
                  <span className="value">{currency(openBalance)}</span>
                </div>
                <div className="credits">
                  <span className="label">
                    {filter?.store
                      ? intl('SCREEN.INVOICES.STORE_CREDITS', { store: filter?.store?.name })
                      : intl('SCREEN.INVOICES.TOTAL_CREDITS')}
                    :
                  </span>
                  <span className="value">{currency(creditAmount)}</span>
                </div>
              </Flex>
            )}
            <ExportContainer>
              <ExportButton
                filter={filterParam}
                variant="text"
                fileName="invoices"
                exportSetting={invoiceExportSettings(invoiceColumns)}
              />
            </ExportContainer>
          </TableSummarySection>
        </TableView2>
      ) : (
        <TableView2
          TABLE={INVOICE_TABLE({
            onViewInvoice,
            onViewRefund,
            onClickStore,
            onChangeStatus,
            onChangeRefundStatus,
            onReceivePayment,
            onAddCredit,
            onEditRefund,
            onViewPaymentGroup,
            isEcommerce: factory?.type === FACTORY_TYPE.ECOMMERCE,
            deleteInvoice,
            isBalanceMode: true,
            isTransaction,
            currentState,
            onDeleteRefund,
            onAddCharge,
          })}
          columns={invoiceColumns}
          columnKey={INVOICES}
          // tabs={tableTabs}
          onTableLoad={onTableLoad}
          pauseAutoRefresh={isModalOpen}
          INNER_TABLE={INVOICE_PAYMENTS_TABLE_MIN(onRejectPayment, onClickPaymentGroup)}
          className="invoices-table"
          filter={filterParam}
          tableConfig={TRANSACTIONS_TABLE_CONFIG(!filter.store, isTransaction)}
          itemActions={itemActions}>
          <FilterSection title={null}>
            <ReportFilter
              stores={stores}
              parentFilter={filter}
              onSearch={updateFilter}
              onChangeStore={onChangeStore}
              defaultRange={DATE_RANGE.TODAY}
              excludeQuery
              extraForm2={transactionFilter}
              placeholder="INPUT.PLACEHOLDER.INVOICE_SEARCH"
            />
          </FilterSection>
          <TableSummarySection>
            {!!storeId && storeId > 0 ? (
              <Flex justifyContent="flex-start" className="summary">
                <div>
                  <span className="label">{intl('SCREEN.TRANSACTIONS.TOTAL_AMOUNT')}:</span>
                  <span className="value">{currency(totals[0])}</span>
                </div>
                <div>
                  <span className="label">{intl('SCREEN.TRANSACTIONS.TOTAL_BALANCE')}:</span>
                  <span className="value">{currency(totals[1])}</span>
                </div>

                <div className="credits">
                  <span className="label">
                    {intl('SCREEN.INVOICES.STORE_CREDITS', { store: filter?.store?.name })}
                  </span>
                  <span className="value">{currency(creditAmount)}</span>
                </div>
              </Flex>
            ) : (
              <div></div>
            )}
            <ExportContainer>
              <ExportButton
                filter={filterParam}
                variant="text"
                fileName="invoices"
                exportSetting={invoiceExportSettings(invoiceColumns, true)}
              />
            </ExportContainer>
          </TableSummarySection>
        </TableView2>
      )}
      <TransactionModals
        ref={childRef}
        stores={stores}
        factory={factory}
        onCloseModals={onCloseModals}
        onChangePaymentGroup={onChangePaymentGroup}
        paymentGroup={paymentGroup}
        currentState={currentState}
        updateFilter={updateFilter}
      />
    </Wrapper>
  );
};

export default InvoiceTableView;

import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const INVOICE_TYPE_FORM = (roles): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.INVOICE_TYPES.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.INVOICE_TYPES.COLS.LEVEL',
        value: 'userLevelId',
        input: INPUT.SELECT,
        gridSize: 6,
        options: (roles || []).map((t) => ({
          labelText: t.label,
          value: t.id + '', // convert to string because Superadmin ID is 0
        })),
        validation: [input.requiredValidation],
      },
    ],
  },
];

import FactoryModel, { initialFactory } from '../../models/factory';
import TaxModel from '../../models/tax';
import VendorModel from '../../models/vendor';
import ShippingModel from '../../models/shipping';
import StoreModel from '../../models/store';
import DiscountModel from '../../models/discount';
import CommRuleModel from '../../models/commRule';
import RegionModel from '../../models/region';
import BankModel from '../../models/bank';
import TermModel from '../../models/term';
import CommPeriodModel from '../../models/commPeriod';
import CommPayModel from '../../models/commPay';
import { SimpleModel } from '$gbusiness/models/simple';
import ChargeModel from '../../models/charge';
import WarehouseModel from '../../models/warehouse';
import InvoiceTypeModel from '../../models/invoiceType';

export const FACTORY_FAILURE = 'FACTORY_FAILURE';
export const FETCH_FACTORIES_SUCCESS = 'FETCH_FACTORIES_SUCCESS';
export const FETCH_MY_FACTORY_SUCCESS = 'FETCH_MY_FACTORY_SUCCESS';
export const FETCH_FACTORY_SUCCESS = 'FETCH_FACTORY_SUCCESS';
export const CREATE_FACTORY_SUCCESS = 'SAVE_FACTORY_SUCCESS';
export const UPDATE_FACTORY_SUCCESS = 'SAVE_FACTORY_SUCCESS';
export const UPDATE_FACTORY_SETTING_SUCCESS = 'UPDATE_FACTORY_SETTING_SUCCESS';
export const DELETE_FACTORY_SUCCESS = 'DELETE_FACTORY_SUCCESS';
export const CLEAN_FACTORY = 'CLEAN_FACTORY';
export const FETCH_PERIODS_SUCCESS = 'FETCH_PERIODS_SUCCESS';
export const FETCH_COMMPAY_SUCCESS = 'FETCH_COMMPAY_SUCCESS';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const FETCH_TAXES_SUCCESS = 'FETCH_TAXES_SUCCESS';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_INVOICE_TYPE_SUCCESS = 'FETCH_INVOICE_TYPE_SUCCESS';
export const FETCH_SHIPPINGS_SUCCESS = 'FETCH_SHIPPINGS_SUCCESS';
export const FETCH_SHIPPING_METHODS_SUCCESS = 'FETCH_SHIPPING_METHODS_SUCCESS';
export const FETCH_CHARGES_SUCCESS = 'FETCH_CHARGES_SUCCESS';
export const FETCH_DISCOUNTS_SUCCESS = 'FETCH_DISCOUNTS_SUCCESS';
export const FETCH_COMMRULES_SUCCESS = 'FETCH_COMMRULES_SUCCESS';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_NEXT_INVOICE_NO_SUCCESS = 'FETCH_NEXT_INVOICE_NO_SUCCESS';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const RESET_FINISHED = 'FACTORY_RESET_FINISHED';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_FACTORY_SUCCESS
    | typeof UPDATE_FACTORY_SUCCESS
    | typeof CLEAN_FACTORY
    | typeof DELETE_FACTORY_SUCCESS
    | typeof RESET_FINISHED;
}

export interface failureType {
  type: typeof FACTORY_FAILURE;
  err: string;
}

export interface FetchFactorySuccessType {
  type: typeof FETCH_FACTORY_SUCCESS;
  factory: FactoryModel;
}

export interface FetchFactoriesSuccessType {
  type: typeof FETCH_FACTORIES_SUCCESS;
  factories: Array<FactoryModel>;
}

export interface FetchPeriodsSuccessType {
  type: typeof FETCH_PERIODS_SUCCESS;
  periods: Array<CommPeriodModel>;
}

export interface FetchRolesSuccessType {
  type: typeof FETCH_ROLES_SUCCESS;
  roles: Array<any>;
}

export interface FetchCommPaySuccessType {
  type: typeof FETCH_COMMPAY_SUCCESS;
  commPay: CommPayModel;
}

export interface FetchMyFactorySuccessType {
  type: typeof FETCH_MY_FACTORY_SUCCESS;
  factory: FactoryModel;
}

export interface UpdateFactorySettingType {
  type: typeof UPDATE_FACTORY_SETTING_SUCCESS;
  settings: any;
}

export interface FetchStoreSuccessType {
  type: typeof FETCH_STORE_SUCCESS;
  store: StoreModel;
}

export interface FetchTaxesSuccessType {
  type: typeof FETCH_TAXES_SUCCESS;
  taxes: Array<TaxModel>;
}

export interface FetchVendorsSuccessType {
  type: typeof FETCH_VENDORS_SUCCESS;
  vendors: Array<VendorModel>;
}

export interface FetchWarehousesSuccessType {
  type: typeof FETCH_WAREHOUSES_SUCCESS;
  warehouses: Array<WarehouseModel>;
}

export interface FetchInvoiceTypesSuccessType {
  type: typeof FETCH_INVOICE_TYPE_SUCCESS;
  invoiceTypes: Array<InvoiceTypeModel>;
}

export interface FetchRegionsSuccessType {
  type: typeof FETCH_REGIONS_SUCCESS;
  regions: Array<RegionModel>;
}

export interface FetchShippingsSuccessType {
  type: typeof FETCH_SHIPPINGS_SUCCESS;
  shippings: Array<ShippingModel>;
}

export interface FetchShippingMethodsSuccessType {
  type: typeof FETCH_SHIPPING_METHODS_SUCCESS;
  shippingMethods: Array<SimpleModel>;
}

export interface FetchChargesSuccessType {
  type: typeof FETCH_CHARGES_SUCCESS;
  charges: Array<ChargeModel>;
}

export interface FetchCommRulesSuccessType {
  type: typeof FETCH_COMMRULES_SUCCESS;
  commRules: Array<CommRuleModel>;
}

export interface FetchDiscountsSuccessType {
  type: typeof FETCH_DISCOUNTS_SUCCESS;
  discounts: Array<DiscountModel>;
}

export interface FetchBanksSuccessType {
  type: typeof FETCH_BANKS_SUCCESS;
  banks: Array<BankModel>;
}

export interface FetchTermsSuccessType {
  type: typeof FETCH_TERMS_SUCCESS;
  terms: Array<TermModel>;
}

export interface FetchNextInvoiceNoType {
  type: typeof FETCH_NEXT_INVOICE_NO_SUCCESS;
  invoiceNo: number;
}

export interface FactoryReducerType {
  factory: FactoryModel;
  factories?: Array<FactoryModel>;
  periods?: Array<CommPeriodModel>;
  commPay?: CommPayModel;
  store: StoreModel | null;
  isFinished: boolean;
  refresh: boolean;
  hisFactory: FactoryModel | null;
  roles: Array<any>;
}

export type FactoryActionTypes =
  | typeOnlyType
  | failureType
  | FetchFactorySuccessType
  | FetchMyFactorySuccessType
  | FetchStoreSuccessType
  | FetchTaxesSuccessType
  | FetchShippingsSuccessType
  | FetchShippingMethodsSuccessType
  | FetchChargesSuccessType
  | FetchFactoriesSuccessType
  | FetchDiscountsSuccessType
  | FetchCommRulesSuccessType
  | FetchRegionsSuccessType
  | FetchRolesSuccessType
  | FetchBanksSuccessType
  | FetchTermsSuccessType
  | FetchPeriodsSuccessType
  | FetchCommPaySuccessType
  | UpdateFactorySettingType
  | FetchNextInvoiceNoType
  | FetchVendorsSuccessType
  | FetchInvoiceTypesSuccessType
  | FetchWarehousesSuccessType;

export const FACTORY_INIT_STATE: FactoryReducerType = {
  factory: initialFactory,
  store: null,
  isFinished: false,
  refresh: true,
  hisFactory: null,
  roles: [],
};

import { INPUT } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';
import { parseISO } from 'date-fns';

export const SN = 'SCREEN.INVOICES.';
export const SNC = 'SCREEN.INVOICES.COLS.';

export const initialInvoiceForm = () => ({
  id: 0,
  invoiceNumber: '',
  noteFactory: '',
  termId: 0,
  shippingMethod: 0,
  user: null,
  store: null,
  invoiceDate: new Date(),
  status: 'OPEN',
});

export const invoiceToForm = (invoice, isEdit = false) => {
  const { id, invoiceNumber, invoiceType, noteFactory, user, createdAt, termId, status } = invoice;
  return {
    id,
    invoiceNumber,
    invoiceTypeId: invoiceType?.id || '0',
    user,
    noteFactory,
    ...(isEdit && { invoiceDate: parseISO(createdAt) }),
    termId,
    status,
  };
};

export const INVOICE_FORM = (
  stores,
  terms,
  discounts,
  invoiceTypes,
  reps,
  shippingMethods,
  onStoreChange,
  isCustomInvoice = false,
  settings,
): Array<InputRowModel> => [
  {
    lineSpace: 8,
    items: [
      {
        input: INPUT.DATE,
        label: SNC + 'DATE',
        value: 'invoiceDate',
        size: 'small',
        gridSize: 3,
      },
      {
        label: SNC + 'STORE',
        value: 'store',
        disableFastField: true,
        input: INPUT.AUTOCOMPLETE,
        gridSize: 4,
        config: {
          optionProp: {
            label: 'name',
            // labelText: (o) => `${o.name || ''} ${o.code ? `(${o.code})` : ''}`,
            value: 'id',
          },
          data: stores,
        },
        size: 'small',
        options: (stores || []).map((s) => ({
          labelText: s.name,
          value: s.id,
        })),
        validation: [input.requiredValidation],
        onChange: (e) => {
          onStoreChange(e);
        },
      },
      {
        label: SNC + 'INVOICE_NO',
        value: 'invoiceNumber',
        disableFastField: true,
        isDisabled: (values) => !values.store || isCustomInvoice,
        input: INPUT.TEXT,
        gridSize: 3,
        size: 'small',
        validation: [input.requiredValidation],
      },
      {
        label: SNC + 'PO_NO',
        value: 'poNo',
        disableFastField: true,
        // isDisabled: (values) => !values.store,
        input: INPUT.TEXT,
        gridSize: 2,
        size: 'small',
      },
    ],
  },
  {
    lineSpace: 8,
    items: [
      // {
      //   label: SNC + 'STATUS',
      //   value: 'status',
      //   input: INPUT.SELECT,
      //   gridSize: 3,
      //   size: 'small',
      //   options: INVOICE_STATUS_OPTIONS,
      // },
      {
        label: SNC + 'INVOICE_TYPE',
        value: 'invoiceTypeId',
        disableFastField: true,
        input: INPUT.SELECT,
        gridSize: 3,
        isHidden: () => !invoiceTypes?.length,
        options: insertEmptyOption(
          (invoiceTypes || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'Default',
          '0',
        ),
      },
      {
        label: SNC + 'TERM',
        value: 'termId',
        isDisabled: (values) => !values.store,
        disableFastField: true,
        input: INPUT.SELECT,
        isHidden: () => !settings?.terms,
        gridSize: 3,
        size: 'small',
        options: insertEmptyOption(
          (terms || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'None',
          '0',
        ),
      },
      {
        label: SNC + 'DISCOUNT',
        value: 'discountId',
        disableFastField: true,
        isDisabled: (values) => !values.store,
        input: INPUT.SELECT,
        isHidden: () => !settings?.discounts,
        gridSize: 3,
        size: 'small',
        options: insertEmptyOption(
          (discounts || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'None',
          '0',
        ),
      },
      {
        label: SNC + 'SALES_REP',
        value: 'user',
        isDisabled: (values) => !values.store,
        disableFastField: true,
        isHidden: () => !settings?.commission,
        input: INPUT.AUTOCOMPLETE,
        config: {
          optionProp: {
            label: 'name',
            value: 'userId',
          },
          data: reps,
        },
        gridSize: 4,
        size: 'small',
        // onChange: (e) => {
        //   onUserChange(e);
        // },
        onChange: ({ formik, value }) => {
          formik.setFieldValue('userId', value?.userId || 0);
        },
      },
      {
        label: SNC + 'TAX',
        value: 'noTax',
        disableFastField: true,
        isDisabled: (values) => !values.store,
        input: INPUT.SELECT,
        gridSize: 2,
        size: 'small',
        options: [
          {
            labelText: 'Tax',
            value: '0',
          },
          {
            labelText: 'No Tax',
            value: '1',
          },
        ],
      },
    ],
  },
  {
    lineSpace: 8,
    items: [
      {
        label: SNC + 'BILL_TO',
        value: 'billTo',
        input: INPUT.TEXTAREA,
        disableFastField: true,
        gridSize: 5,
        rows: 2,
        isDisabled: (values) => !values.store,
        className: 'ship-input',
        // disabled: true,
        size: 'small',
      },
      {
        label: SNC + 'SHIP_CITY',
        value: 'shipTo',
        input: INPUT.TEXTAREA,
        disableFastField: true,
        gridSize: 5,
        rows: 2,
        className: 'ship-input',
        isDisabled: (values) => !values.store,
        // disabled: true,
        size: 'small',
      },
      {
        label: SNC + 'SHIPPING_METHOD',
        value: 'shippingMethodId',
        disableFastField: true,
        input: INPUT.SELECT,
        gridSize: 2,
        size: 'small',
        options: insertEmptyOption(
          (shippingMethods || []).map((s) => ({
            labelText: s.name,
            value: s.id,
          })),
          'None',
          '0',
        ),
      },
      {
        label: SNC + 'STORE_NOTE',
        value: 'storeNote',
        input: INPUT.TEXTAREA,
        disableFastField: true,
        gridSize: 6,
        rows: 5,
        className: 'ship-input',
        disabled: true,
        // disabled: true,
        size: 'small',
      },
      {
        label: SN + 'INVOICE_NOTES',
        value: 'noteFactory',
        className: 'ship-input',
        input: INPUT.TEXTAREA,
        gridSize: 6,
        rows: 5,
        size: 'small',
      },
    ],
  },
];

export default INVOICE_FORM;

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';

import { configs } from '$configs';
import { currency } from '$gbusiness/helpers/util';
import { Link } from '$gcomponents/primitives';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToStore } from '$fbusiness/models/store';
import PATH from '$business/enums/paths';
import ExportModel from '$gbusiness/models/export';
import { addressNode } from '$gcomponents/utils/general';
import { ACCESS } from '$fbusiness/enums/access';
import { accessHide, isAccessible } from '$fbusiness/helpers/util';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: (e) => `${e.name} ${e.address} ${e.phone}`,
  selectedValue: (e) => e.firstName,
  onSelect: (e, history) => {
    history.push(`${PATH.FACTORIES}/${e.userId}`);
  },
};

export const defaultStoreColumns = [
  'id',
  'stores.regionName',
  // 'salesmen',
  'addr.formatted',
  //'addr.city',
  //'addr.state',
  //'addr.zip',
  'addr2.formatted',
  //'addr2.city',
  //'addr2.state',
  //'addr2.zip',
  'phone',
  // 'email',
  // 'discount',
  // 'term',
  // 'note',
  'credits',
  'openBalance',
];

export const storeExportSettings = (settings, columns: any = []): ExportModel => {
  const cols: any = columns.reduce((acc, c) => ((acc[c] = true), acc), {}); // eslint-disable-line no-sequences
  const { regions, commission } = settings;
  return {
    url: configs.api.store.general,
    method: 'GET',
    deriveToModel: deriveRawToStore,
    deriveTable: (item) => ({
      ...(cols['id'] && { StoreId: item.id }),
      AccountNo: item.code,
      Name: item.name,
      ...(cols['regionName'] && { Territory: item.sku }),
      ...(cols['salesmen'] && { SalesReps: item.sku }),
      ...(cols['addr.formatted'] && { BillingAddress: item.address?.formatted || '' }),
      ...(cols['addr.city'] && { BillingCity: item.address?.city || '' }),
      ...(cols['addr.state'] && { BillingState: item.address?.state || '' }),
      ...(cols['addr.zip'] && { BillingZip: item.zip?.state || '' }),
      ...(cols['addr2.formatted'] && { ShippingAddress: item.shippingAddress?.formatted || '' }),
      ...(cols['addr2.city'] && { ShippingCity: item.shippingAddress?.city || '' }),
      ...(cols['addr2.state'] && { ShippingState: item.shippingAddress?.state || '' }),
      ...(cols['addr2.zip'] && { ShippingZip: item.shippingAddress?.zip || '' }),
      ...(cols['phone'] && { Phone: item.phone }),
      ...(cols['email'] && { Email: item.email }),
      ...(cols['discount'] && { Discount: item.discount?.name || '' }),
      ...(cols['term'] && { DefaultTerm: item.term?.name || '' }),
      ...(cols['note'] && { Note: item.settings?.note || '' }),
      ...(cols['credits'] && { Credits: currency(item.credits) }),
      ...(cols['openBalance'] && { OpenBalance: currency(item.openBalance) }),
      ...(regions && { Territory: item.regionName }),
      ...(commission && { SalesReps: (item.salesmen || []).map((s, i) => s.name).join(',') }),
      Address: item.formatted,
      Phone: item.phone,
      Email: item.email,
      Credits: currency(item.credits),
      OpenBalance: item.openBalance,
    }),
    sortKey: 'stores.name',
    sortOrder: SORT.ASC,
  };
};

export const STORE_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.store.general,
    method: 'GET',
    deriveToModel: deriveRawToStore,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 1400,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const STORE_TABLE = (features, currentState): Array<CellModel> => [
  {
    label: 'SCREEN.STORES.COLS.ID',
    value: 'id',
    align: 'center',
    width: 80,
  },
  {
    label: 'SCREEN.STORES.COLS.ACCOUNTNO',
    value: 'code',
    align: 'center',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.STORES.COLS.NAME',
    value: 'name',
    component: (row) => <Link route={`${PATH.STORES}/${row.id}`}>{row.name}</Link>,
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: !features.regions,
    label: 'SCREEN.STORES.COLS.REGION',
    value: 'region.name',
    component: (row) => row.regionName,
    sortKey: 'regionId',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: !features.commission,
    label: 'SCREEN.STORES.COLS.SALESMAN',
    value: 'salesmen',
    component: (row) => (
      <div>
        {(row.salesmen || []).map((s, i) => (
          <div key={i}>{s.name}</div>
        ))}
      </div>
    ),
    width: 220,
  },
  {
    label: 'SCREEN.STORES.COLS.ADDRESS',
    value: 'addr.formatted',
    component: (row) => <>{addressNode(row.address)}</>,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.STORES.COLS.CITY',
    value: 'addr.city',
    sortable: SORT.ASC,
    component: (row) => <>{row.address?.city || ''}</>,
    width: 200,
  },
  {
    label: 'SCREEN.STORES.COLS.STATE',
    value: 'addr.state',
    sortable: SORT.ASC,
    component: (row) => <>{row.address?.state || ''}</>,
    width: 100,
  },
  {
    label: 'SCREEN.STORES.COLS.ZIP',
    value: 'addr.zip',
    sortable: SORT.ASC,
    component: (row) => <>{row.address?.zip || ''}</>,
    width: 140,
  },
  {
    label: 'SCREEN.STORES.COLS.SHIPPING_ADDRESS',
    value: 'addr2.formatted',
    component: (row) => <>{addressNode(row.shippingAddress)}</>,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.STORES.COLS.CITY2',
    value: 'addr2.city',
    sortable: SORT.ASC,
    component: (row) => <>{row.shippingAddress?.city || ''}</>,
    width: 200,
  },
  {
    label: 'SCREEN.STORES.COLS.STATE2',
    value: 'addr2.state',
    sortable: SORT.ASC,
    component: (row) => <>{row.shippingAddress?.state || ''}</>,
    width: 100,
  },
  {
    label: 'SCREEN.STORES.COLS.ZIP2',
    value: 'addr2.zip',
    sortable: SORT.ASC,
    component: (row) => <>{row.shippingAddress?.zip || ''}</>,
    width: 140,
  },
  {
    label: 'SCREEN.STORES.COLS.PHONE',
    value: 'phone',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.STORES.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.STORES.COLS.DISCOUNT',
    value: 'discount',
    align: 'center',
    component: (row) => <>{row.discount?.name || ''}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.STORES.COLS.TERM',
    value: 'term',
    align: 'center',
    component: (row) => <>{row.term?.name || ''}</>,
    sortable: SORT.DSC,
    width: 200,
  },
  {
    label: 'SCREEN.STORES.COLS.NOTE',
    value: 'note',
    component: (row) => <>{row.settings?.note || ''}</>,
    width: 320,
  },
  ...(isAccessible(ACCESS.MONEY.VIEW, currentState)
    ? [
        {
          label: 'SCREEN.STORES.COLS.CREDITS',
          value: 'credits',
          component: (row) => <>{currency(row.credits)}</>,
          className: accessHide(ACCESS.MONEY.VIEW),
          sortable: SORT.DSC,
          width: 200,
        },
        {
          label: 'SCREEN.STORES.COLS.OPEN_BALANCE',
          value: 'openBalance',
          className: accessHide(ACCESS.MONEY.VIEW),
          component: (row) => <>{currency(row.openBalance)}</>,
          sortable: SORT.DSC,
          width: 200,
        },
      ]
    : []),
  {
    label: '',
    value: '',
    align: 'center',
    alwaysShow: true,
    component: (row, actions) => {
      return (
        <Button className="left" fullWidth onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 80,
  },
];

export default STORE_TABLE;

import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail } from '$gbusiness/services/api';
import { deriveRawToBroadcast } from '../../models/broadcast';
import {
  BroadcastActionTypes,
  FETCH_BROADCAST_SUCCESS,
  CLEAN_BROADCAST,
  BROADCAST_FAILURE,
  FETCH_BROADCASTS_SUCCESS,
  UPDATE_BROADCAST_SUCCESS,
} from './types';

export function fetchBroadcasts(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.broadcast.general,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, BROADCAST_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_BROADCASTS_SUCCESS,
      broadcasts: response.list.map((d) => deriveRawToBroadcast(d)),
    });
  };
}

export function fetchBroadcast(broadcastId): any {
  return async (dispatch: Dispatch) => {
    if (!broadcastId || isNaN(broadcastId)) {
      dispatch({
        type: FETCH_BROADCAST_SUCCESS,
        broadcast: undefined,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.broadcast.general + '/' + broadcastId,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BROADCAST_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: FETCH_BROADCAST_SUCCESS,
      broadcast: deriveRawToBroadcast(response.data),
    });
  };
}

export function updateBroadcast(id, param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.broadcast.general + '/' + id,
      method: 'PUT',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BROADCAST_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: UPDATE_BROADCAST_SUCCESS,
      broadcast: response.data,
    });
  };
}

export function deleteBroadcast(broadcastId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.broadcast.general + (broadcastId ? '/' + broadcastId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, BROADCAST_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }
    return Promise.resolve(response.list);
  };
}

export function dehydrate(): BroadcastActionTypes {
  return { type: CLEAN_BROADCAST };
}

import { Dispatch } from 'redux';

import { configs } from '$configs';
import {
  dispatchLoading,
  fetchApi,
  handleApiFail,
  handleApiSuccess,
  handleErr,
  handleErrJson,
} from '$gbusiness/services/api';
import { deriveRawToUser, emptyUser } from '$fbusiness/models/user';
import {
  UserActionTypes,
  FETCH_USER_SUCCESS,
  CLEAN_USER,
  SAVE_USER_SUCCESS,
  USER_FAILURE,
  FETCH_USERS_SUCCESS,
  RESET_FINISHED,
} from './types';
import { LOADING } from '$gbusiness/redux/loading/types';
import { generateGetParam } from '$gbusiness/helpers/util';

export function fetchUser(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_USER_SUCCESS,
        user: emptyUser,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.users.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, USER_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_USER_SUCCESS,
      user: deriveRawToUser(response.data),
    });
  };
}

export function fetchUsers(param: any): any {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
    });

    const newParam = generateGetParam(param);

    const response = await fetchApi({
      url: configs.api.users.general + (newParam && `?${newParam}`),
      param,
      method: 'GET',
    });

    if (!response || !response.list) {
      return;
    }

    dispatch({
      type: FETCH_USERS_SUCCESS,
      users: response.list.map(deriveRawToUser),
    });
  };
}

export function saveUser(userId, user): any {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SAVING',
    });

    const response = await fetchApi({
      url: configs.api.users.general + (userId ? '/' + userId : ''),
      param: {
        ...user,
        ...(user.firstName && { first_name: user.firstName }),
        ...(user.lastName && { last_name: user.lastName }),
      },
      method: userId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, USER_FAILURE, response, 'MESSAGE.SAVE_FAIL', true);
      return;
    } else {
      handleApiSuccess(dispatch, SAVE_USER_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteUser(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.users.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, USER_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.DELETE_SUCCESS', 'small');
    }
  };
}

export function contactUs(param, files: any = []): any {
  return async (dispatch: Dispatch, getState) => {
    const store = getState();
    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.contact}`;
    const globalHeaders = store.init.headers;
    const { accessToken, userId } = store.localStorage;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(userId && { userId: userId?.toString() }),
      ...globalHeaders,
    };

    const formData = new FormData();
    for (const fileData of files) {
      const file: File = fileData.file;
      if (file) formData.append('files[]', file);
    }
    // formData.append('files', files);
    for (const prop in param) {
      formData.append(prop, param[prop]);
    }

    const response: any = await fetch(url, {
      method: 'POST',
      body: formData,
      referrer: '',
      headers,
    }).catch(handleErr);
    const success = response.status && response.status >= 200 && response.status < 300;
    const result = await response.json().catch((err) => handleErrJson(err, success));

    if (!result || !result?.success) {
      handleApiFail(dispatch, USER_FAILURE, response, 'MESSAGE.EMAIL_FAILED', true);
      return Promise.resolve(false);
    }

    handleApiSuccess(dispatch, 'LOADED', 'MESSAGE.EMAIL_SENT', 'small');
    return Promise.resolve(result);
  };
}

// export function contactUs(param): any {
//   return async (dispatch: Dispatch) => {
//     dispatchLoading(dispatch, 'PROGRESS.SENDING');

//     const response = await fetchApi({
//       url: configs.api.contact,
//       method: 'POST',
//       param,
//     });

//     if (!response || !response?.success) {
//       handleApiFail(dispatch, USER_FAILURE, response, 'MESSAGE.EMAIL_FAILED', true);
//       return Promise.resolve(false);
//     }

//     handleApiSuccess(dispatch, 'LOADED', 'MESSAGE.EMAIL_SENT', 'small');
//     return Promise.resolve(response);
//   };
// }

export function resetFinished(): UserActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): UserActionTypes {
  return { type: CLEAN_USER };
}

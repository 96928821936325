import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Card } from '@mui/material';
import { SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .reply-container {
    padding: ${SPACE.MEDIUM} ${SPACE.XLARGE};
    margin-bottom: ${SPACE.XLARGE};
    .admin-memo .MuiOutlinedInput-root {
      background-color: #fafafa;
    }
  }
  .button-container {
    justify-content: center;
    margin-bottom: ${SPACE.XLARGE};
  }
  .submit-button {
    margin-left: ${SPACE.LARGE};
  }
  .attachform {
    padding-bottom: ${SPACE.LARGE};
  }
`;

export const MessageBoxWrapper = styled(Card)`
  margin-bottom: ${SPACE.XLARGE};
  &.unread {
    background-color: var(--ion-highlight-color);
  }
  .MuiBox-root {
    padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
    &.content {
      min-height: 60px;
      padding-top: 12px;
    }
  }
  .posted {
    opacity: 0.7;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast-bw);
    .name {
      font-weight: ${WEIGHT.BOLD};
    }
    &.admin {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast-bw);
    }
  }
  .attached-files {
    padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
    justify-content: center;
    > a {
      margin: 4px 8px;
    }
  }
  .contact {
    display: flex;
    > * {
      color: var(--ion-color-primary-contrast-bw);
      margin-right: ${SPACE.LARGE};
      > * {
        color: var(--ion-color-primary-contrast-bw);
      }
    }
  }
  .admin-memo .MuiOutlinedInput-root {
    background-color: #fafafa;
  }

  .name,
  .phone,
  .email {
    font-weight: ${WEIGHT.BOLD};
  }
`;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import intl from '$gintl';
import { TableView2 } from '$gcomponents/widgets';
import ItemActionModel from '$gbusiness/models/itemAction';
import { dialog } from '$gcomponents/reusables';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';

import { BATCHES } from '$fbusiness/enums/columns';
import FabButton from '$fcomponents/fabButton';
import FactoryModel from '$fbusiness/models/factory';
import { deriveRawToItemBatch, initialBatch } from '$fbusiness/models/itemBatch';
import { accessHide, updatedFilter } from '$fbusiness/helpers/util';
import { batchActions } from '$fbusiness/redux/batch';
import ItemModel from '$fbusiness/models/item';

import BatchFilter, {
  defaultBatchFilter,
  defaultExpirationFilter,
  filterToParam,
  getExpirationThresDate,
} from './batchFilter';
import { BATCH_TABLE, BATCH_TABLE_CONFIG, defaultBatchColumns } from './batchTable';
import BatchModal from './batchModal';
import { itemText } from '$gbusiness/helpers/util';
import AddSerialModal from './addSerialModal';
import SerialRemoveModal from '../serialsScreen.tsx/serialRemoveModal';
import { TableSummarySection } from '$components/transactionModals/styles';
import { isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface BatchSectionProps {
  currentState;
  factory: FactoryModel;
  item?: ItemModel;
  items?: Array<any>;
  isItemPage?: boolean;
  isExpiration?: boolean;
  onRefresh?: Function;
}

const BatchSection = React.forwardRef<BatchSectionProps, any>(
  ({ factory, item, items, isExpiration, currentState, onRefresh, isItemPage = false }, ref) => {
    const dispatch = useDispatch();

    React.useImperativeHandle(ref, () => ({
      currentState,
      factory,
      item,
      items,
      isExpiration,
      isItemPage,
      refreshTable,
      onRefresh,
      onEditBatch,
    }));

    const { settings } = factory;

    const columns = useSelector((state: any) => state.localStorage.columns);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const [showSerialModal, setShowSerialModal] = useState(false);
    const [showSerialRemoveModal, setShowSerialRemoveModal] = useState(false);
    const [batch, setBatch] = useState(initialBatch);
    const [totalExpired, setTotalExpired] = useState(0);
    const [totalExpiring, setTotalExpiring] = useState(0);
    const [filter, setFilter] = useState(
      filterToParam({
        ...(isExpiration ? defaultExpirationFilter(factory.settings) : defaultBatchFilter()),
        item,
        dateExpireSoon: getExpirationThresDate(settings, true),
        ...(isExpiration && { isExpiration }),
      }),
    );

    const onAddSerial = async (row) => {
      if (row) {
        await dispatch(batchActions.fetchBatch(row.id, true)).then(async (newBatch) => {
          await setBatch(deriveRawToItemBatch(newBatch));
          setShowSerialModal(true);
        });
      }
    };

    const onRemoveProducts = async (row) => {
      if (row) {
        await dispatch(batchActions.fetchBatch(row.id, true)).then(async (newBatch) => {
          await setBatch(deriveRawToItemBatch(newBatch));
          setShowSerialRemoveModal(true);
        });
      }
    };

    const onEditBatch = async (row) => {
      if (row) {
        await dispatch(batchActions.fetchBatch(row.id, true)).then(async (newBatch) => {
          await setBatch(deriveRawToItemBatch(newBatch));
          setShowBatchModal(true);
        });
      } else {
        await setBatch(initialBatch);
        setShowBatchModal(true);
      }
    };

    const onTableLoad = (a, b, c) => {
      setTotalExpired(c.qtyExpired);
      setTotalExpiring(c.qtyExpireSoon);
    };

    const refreshTable = () => {
      setFilter(updatedFilter(filter));
      if (onRefresh) onRefresh();
    };

    const updateFilter = (newFilter) => {
      setFilter(updatedFilter(filter, newFilter));
    };

    const onDeleteBatch = async (id) => {
      dialog.confirm({
        message: 'MESSAGE.DELETE_WARNING',
        onPress: async () => {
          await dispatch(batchActions.deleteBatch(id));
          refreshTable();
        },
      });
    };

    const itemActions: Array<ItemActionModel> = [
      ...(!isExpiration
        ? [
            {
              label: intl('BUTTON.EDIT'),
              eventName: 'edit',
              disabled: () => !isAccessible(ACCESS.ACTION.RECEIVING.EDIT, currentState),
              onClick: (row) => onEditBatch(row),
            },
            {
              label: itemText('ADD', 'SERIAL'),
              eventName: 'add',
              disabled: () => !isAccessible(ACCESS.ACTION.SERIAL.CREATE, currentState),
              onClick: (row) => onAddSerial(row),
            },
          ]
        : []),
      {
        label: intl('BUTTON.REMOVE_PRODUCTS'),
        eventName: 'remove',
        disabled: () => !isAccessible(ACCESS.ACTION.RECEIVING.REMOVE_PRODUCTS, currentState),
        onClick: (row) => onRemoveProducts(row),
      },
      ...(!isExpiration
        ? [
            {
              label: intl('BUTTON.DELETE'),
              eventName: 'delete',
              disabled: () => !isAccessible(ACCESS.ACTION.RECEIVING.DELETE, currentState),
              onClick: (row) => onDeleteBatch(row.id),
            },
          ]
        : []),
    ];

    const batchColumns =
      columns && columns[BATCHES] ? columns[BATCHES] || defaultBatchColumns : defaultBatchColumns;

    const batchTable = BATCH_TABLE(isItemPage, onEditBatch, isExpiration);
    const { vendors } = factory;

    return (
      <>
        <TableView2
          filter={filter}
          itemActions={itemActions}
          TABLE={batchTable}
          columnKey="batch"
          onTableLoad={onTableLoad}
          columns={isItemPage ? undefined : batchColumns}
          tableConfig={BATCH_TABLE_CONFIG(isItemPage, isExpiration)}>
          <>
            {!isItemPage && (
              <FilterSection title={null}>
                <BatchFilter
                  isExpiration={isExpiration}
                  vendors={vendors || []}
                  items={items || []}
                  columns={batchColumns}
                  parentFilter={filter}
                  factory={factory}
                  onSearch={updateFilter}
                />
              </FilterSection>
            )}
            {
              <TableSummarySection>
                {isExpiration && (
                  <div className="summary">
                    <div>
                      <span className="label">{intl('SCREEN.BATCH.TOTAL_EXPIRED')}:</span>
                      <span className="value">{totalExpired}</span>
                    </div>
                    <div className="credits">
                      <span className="label">{intl('SCREEN.BATCH.TOTAL_EXPIRING')}:</span>
                      <span className="value">{totalExpiring}</span>
                    </div>
                  </div>
                )}
              </TableSummarySection>
            }
          </>
        </TableView2>

        {!isItemPage && (
          <FabButton onClick={onEditBatch} className={accessHide(ACCESS.ACTION.RECEIVING.CREATE)} />
        )}
        <BatchModal
          factory={factory}
          batch={batch}
          item={item}
          show={showBatchModal}
          onRefresh={refreshTable}
          isItemPage={isItemPage}
          onClose={() => setShowBatchModal(false)}
        />
        <AddSerialModal
          batch={batch}
          onRefresh={refreshTable}
          show={showSerialModal}
          onClose={() => setShowSerialModal(false)}
        />
        <SerialRemoveModal
          batch={batch}
          show={showSerialRemoveModal}
          onClose={() => setShowSerialRemoveModal(false)}
          onRefresh={refreshTable}
        />
      </>
    );
  },
);

export default BatchSection;

import AddressModel from '$gbusiness/models/address';

export default interface WarehouseModel {
  id: number;
  name: string;
  short?: string;
  address?: AddressModel | undefined;
  itemCount?: number;
  settings?: any;
}

export const deriveRawToWarehouse = (raw) => {
  if (!raw) return undefined;
  return {
    id: raw.id,
    name: raw.name,
    short: raw.short || '',
    ...(raw.address && { address: raw.address }),
    itemCount: raw.itemCount || 0,
  };
};

export const initialWarehouse = {
  id: 0,
  name: '',
  short: '',
  itemCount: 0,
};

export const PATH = {
  MENU: '/category',
  HOME: '/home',
  BATCH: '/batch',
  EXPIRATION: '/expiration',
  SERIAL: '/serial',
  PROFILE: '/profile',
  INVENTORY: '/inventory',
  FACTORIES: '/factories',
  STORES: '/stores',
  SETTINGS: '/settings',
  VENDORS: '/settings/vendors',
  DEPARTMENTS: '/inventory/1',
  CATEGORIES: '/inventory/2',
  MULTI_CATEGORIES: '/categories',
  ITEMS: '/inventory/3',
  ORDERS: '/orders',
  ORDER: '/order',
  ORDER_STORE: '/order/1',
  ORDER_DEPT: '/order/2',
  INVOICES: '/invoices',
  INVOICE: '/invoice',
  UPDATE_INVOICE: '/update-invoice/',
  FILES: '/files',
  DAILY_REPORT: '/dailyreports',
  MESSAGE: '/message',
  MESSAGES: '/messages',
  NEW_MESSAGE: '/new-message',
  MESSAGES_ID: '/message-id',
  CONTACT_US: '/contact-us',
  LOGIN: '/login',
  MOCK: '/mock',
  NOTIFICATION: '/notification',
  REPORT_STORE: '/report-store',
  REPORT_FILTER: '/report-filter',
  REPORT_REP: '/report-rep',
  REPORT_TERM: '/report-term',
  REPORT_SHIPPING: '/report-shipping',
  REPORT_REGION: '/report-region',
  REPORT_AR: '/report-ar',
  REPORT_ITEM: '/report-item',
  REPORT_REFUND_ITEM: '/report-refund-item',
  REPORT_TOP: '/report-top',
  REPORT_BOTTOM: '/report-bottom',
  REPORT_ITEM_DETAILS: '/report-details',
  REPORT_ITEM_DETAILS2: '/report-item-details2',
  REPORT_REFUND_DETAILS: '/report-refund-details',
  REPORT_INVOICES: 'report-invoices',
  REPORT_BALANCE: '/report-balance',
  REPORT_SUMMARY: '/report-summary',
  REPORT_SNAPSHOT: '/report-snapshot',
  KONGDOG: 'https://gmsiso.com/cloudrestaurant/#/sales',
  DISCOUNTS: '/discounts',
  COMMRULES: '/commrules',
  COMMISSIONS: '/commissions',
  COMMINVOICES: '/comminvoices',
  COMMREFUNDS: '/commrefunds',
  COMMCREDITS: '/commcredits',
  TRANSACTIONS: '/transactions',
  BALANCE_DETAILS: '/balance-details',
  SALESMEN: '/salesmen',
  TOS: '/tos',
  REFUNDS: '/refunds',
  CREATE_CREDIT: '/create-credit',
  CREATE_REFUND: '/create-refund',
  EDIT_REFUND: '/edit-refund',
  REGIONS: '/regions',
  LABELS: '/labels',
  INVOICE_TYPES: '/invoice-types',
  TERMS: '/terms',
};

export default PATH;

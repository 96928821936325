import {
  BroadcastActionTypes,
  BroadcastReducerType,
  FETCH_BROADCAST_SUCCESS,
  FETCH_BROADCASTS_SUCCESS,
  BROADCAST_INIT_STATE,
  UPDATE_BROADCAST_SUCCESS,
} from './types';

export default function broadcastReducer(
  state: BroadcastReducerType = BROADCAST_INIT_STATE,
  action: BroadcastActionTypes,
): BroadcastReducerType {
  switch (action.type) {
    case FETCH_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcast: action.broadcast,
      };
    case FETCH_BROADCASTS_SUCCESS:
      return {
        ...state,
        broadcasts: action.broadcasts,
      };
    case UPDATE_BROADCAST_SUCCESS:
      return {
        ...state,
        broadcasts: state.broadcasts.filter((b) => b.id !== action.broadcast.id),
      };
    default:
      return state;
  }
}

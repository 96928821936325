export const SUPERADMIN = 0;
export const ADMIN = 1;
export const MANAGER = 2;
export const EMPLOYEE = 3;
export const ASSISTANT = 4;

const SCREEN_ACCESS = {
  MOCK: SUPERADMIN,
  USERS: MANAGER,
  USER: MANAGER,
  FACTORIES: SUPERADMIN,
  FACTORY: SUPERADMIN,
  STORES: EMPLOYEE,
  STORE: EMPLOYEE,
  DEPARTMENTS: EMPLOYEE,
  DEPARTMENT: EMPLOYEE,
  CATEGORIES: EMPLOYEE,
  CATEGORY: EMPLOYEE,
  ITEMS: EMPLOYEE,
  ITEM: EMPLOYEE,
  RECEIVING: EMPLOYEE,
  EXPIRATION: EMPLOYEE,
  SERIAL: EMPLOYEE,
  ORDERS: EMPLOYEE,
  INVOICES: EMPLOYEE,
  INVOICE: EMPLOYEE,
  CREDIT: ADMIN,
  REFUNDS: EMPLOYEE,
  REFUND: EMPLOYEE,
  COMMISSIONS: MANAGER,
  BALANCE_DETAILS: EMPLOYEE,
  FILES: EMPLOYEE,
  EMAIL_NOTIFICATION: MANAGER,
  SETTING: {
    GENERAL: MANAGER,
    LABELS: MANAGER,
    REGIONS: MANAGER,
    VENDORS: MANAGER,
    DISCOUNTS: MANAGER,
    COMMRULES: MANAGER,
    TERMS: MANAGER,
    WAREHOUSES: MANAGER,
    SALESMEN: MANAGER,
    INVOICE_TYPES: ADMIN,
  },
  REPORT: {
    DAILY: MANAGER,
    STORE: MANAGER,
    FILTER: MANAGER,
    AR: MANAGER,
    REFUND: MANAGER,
    ITEM: MANAGER,
    TOP: MANAGER,
    BOTTOM: MANAGER,
    REP: MANAGER,
    TERM: MANAGER,
    SHIPPING: MANAGER,
    REGION: MANAGER,
    SALES: MANAGER,
    SNAPSHOT: MANAGER,
    BALANCE: MANAGER,
    ITEM_DETAILS: MANAGER,
    ITEM_DETAILS2: MANAGER,
    REFUND_DETAILS: MANAGER,
  },
  MESSAGES: EMPLOYEE,
  MESSAGE: EMPLOYEE,
  KONGDOG: EMPLOYEE,
};

export const ACCESS = {
  SCREEN: {
    ...SCREEN_ACCESS,
  },
  ACTION: {
    FILE: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
      CLEAN: MANAGER,
    },
    SUPPORT: {
      CREATE: EMPLOYEE,
      REPLY: EMPLOYEE,
    },
    DEPARTMENT: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
    },
    CATEGORY: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
      RANK: MANAGER,
      ALPHABETIZE: MANAGER,
    },
    ITEM: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
      RANK: MANAGER,
      IMPORT: MANAGER,
      EXPORT: MANAGER,
    },
    RECEIVING: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
      REMOVE_PRODUCTS: MANAGER,
    },
    SERIAL: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
    },
    ORDER: {
      VIEW: EMPLOYEE,
      CHANGE_STATUS: MANAGER,
      SHIP: EMPLOYEE,
      CANCEL: MANAGER,
      UPDATE_NOTE: EMPLOYEE,
    },
    INVOICE: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      VIEW: EMPLOYEE,
      EMAIL: EMPLOYEE,
      DOWNLOAD: EMPLOYEE,
      HISTORY: MANAGER,
      CHARGE: MANAGER,
      CHANGE_STATUS: ADMIN,
    },
    REFUND: {
      CREATE: ADMIN,
      EDIT: ADMIN,
      DELETE: ADMIN,
      VIEW: EMPLOYEE,
    },
    CREDIT: {
      CREATE: ADMIN,
      EDIT: ADMIN,
      VIEW: EMPLOYEE,
      DELETE: ADMIN,
      CHANGE_STATUS: ADMIN,
    },
    CHARGE: {
      CREATE: ADMIN,
      EDIT: ADMIN,
    },
    PAYMENT: {
      CREATE: ADMIN,
      CREDIT: ADMIN,
      VIEW: ADMIN,
      REJECT: ADMIN,
    },
    USER: {
      CREATE: ADMIN,
      EDIT: ADMIN,
      DELETE: ADMIN,
    },
    STORE_USER: {
      CREATE: MANAGER,
      EDIT: MANAGER,
      DELETE: MANAGER,
    },
    STORE: {
      CREATE: ADMIN,
      EDIT: ADMIN,
      DELETE: ADMIN,
    },
    FACTORY: {
      CREATE: SUPERADMIN,
      EDIT: SUPERADMIN,
      DELETE: SUPERADMIN,
    },
    SETTING: {
      GENERAL: {
        EDIT: ADMIN,
      },
      TAX: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
        APPLY_ALL: ADMIN,
        UNAPPLY_ALL: ADMIN,
      },
      SHIPPING: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      SHIPPING_METHOD: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      CHARGE: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      REGION: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      VENDOR: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      DISCOUNT: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      SALESMEN: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      TERM: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      WAREHOUSE: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      COMMISSION_RULE: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
      PAYOUT: {
        EDIT: ADMIN,
      },
      SERIAL_STATUS: {
        CREATE: ADMIN,
        EDIT: ADMIN,
        DELETE: ADMIN,
      },
    },
    EMAIL_NOTIFICATION: ADMIN,
  },
  MONEY: {
    VIEW: EMPLOYEE,
  },
};

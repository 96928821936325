import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { percentage } from '$gbusiness/helpers/util';
import { deriveRawToCommRule } from '$fbusiness/models/commRule';
import { Delete } from '@mui/icons-material';
import { DISCOUNT_RULE } from '$fbusiness/enums/options/discountRule';
import { accessHide } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

export const COMMRULE_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.commRule,
    method: 'GET',
    deriveToModel: deriveRawToCommRule,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const COMMRULE_TABLE = (onClickRow, onDeleteItem): Array<CellModel> => [
  {
    label: 'SCREEN.COMMRULES.COLS.NAME',
    value: 'name',
    component: (row) => (
      <div className="link" onClick={() => onClickRow(row)}>
        {row.name}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.COMMRULES.COLS.RATE',
    value: 'rate',
    component: (row) => <div>{percentage(row.rate)}</div>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.COMMRULES.COLS.DISCOUNT_RATE',
    value: 'discountRate',
    component: (row) => {
      const { settings } = row;
      if (settings.type === DISCOUNT_RULE.FIXED) return <div>{percentage(row.discountRate)}</div>;
      if (settings.type === DISCOUNT_RULE.RULE)
        return (
          <div>
            <div>{`${percentage(settings.commission1)} / ${percentage(settings.discountRate1)}DC`}</div>
            <div>{`${percentage(settings.commission2)} / ${percentage(settings.discountRate2)}DC`}</div>
          </div>
        );
      return 'None';
    },
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: '',
    value: 'checkNo',
    className: 'action',
    component: (row) => (
      <div className="button-group">
        <div>
          <Delete
            onClick={() => onDeleteItem(row.id)}
            className={`pointer ${accessHide(ACCESS.ACTION.SETTING.COMMISSION_RULE.DELETE)}`}
          />
        </div>
      </div>
    ),
    width: 80,
    minWidth: 80,
  },
];

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header as FactoryHeader } from '$gcomponents/widgets';
import StoreHeader from '../../../components/header';

import { IonPageWrapper } from './styles';
import { input } from '$gbusiness/helpers';
import ThreadModel from '$fbusiness/models/thread';
import { threadActions } from '$fbusiness/redux/thread';
import PATH from '$fbusiness/enums/paths';
import intl from '$gintl';
import MessageBox from './messageBox';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import REPLY_FORM from './replyForm';
import { FormSection } from '$gcomponents/reusables';
import AttachmentForm from '$fcomponents/contactForm/attachmentForm';
import { Card } from '@mui/material';
import { Button } from '$gcomponents/primitives';
import { fileActions } from '$fbusiness/redux/file';
import { accessHide } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface MessageScreenProps {
  factory;
  history;
  onHydrate;
  onDehydrate;
  currentState;
  thread: ThreadModel;
  match;
}

const MessageScreen: React.FC<MessageScreenProps> = ({ factory, thread, history, currentState, match }) => {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<Array<any>>([]);
  const [overMax, setOverMax] = useState(false);

  useEffect(() => {
    if (thread) dispatch(threadActions.markRead(thread.id));
  }, [thread]); // eslint-disable-line react-hooks/exhaustive-deps

  const { user, isFactory, settings } = currentState;
  const { params } = match;

  const replyForm = REPLY_FORM(isFactory);

  const validateForm = (values) => {
    return input.validateError(replyForm, values);
  };

  const onSubmitEmail = async (values, formik) => {
    const { body, name, email, adminMemo } = values;
    const param: any = {
      body,
      adminMemo,
      name,
      email,
      threadId: thread.id,
      isAdmin: currentState.isFactory ? 1 : 0,
      userId: currentState.user?.userId,
    };

    if (files.length) {
      const fileResult = await dispatch(fileActions.addFiles(files, 'message'));
      if (fileResult[0]) param.files = fileResult[0];
    }

    const result = await dispatch(threadActions.replyThread(param));
    if (result) {
      formik.resetForm();
      setFiles([]);
      history.push(PATH.MESSAGES);
    }
  };

  const onChangeAttachment = (newFiles, isOver) => {
    setFiles(newFiles);
    setOverMax(isOver);
  };

  const title = `Ticket #${thread?.ticketNumber} - ${
    thread?.title || (params?.threadId === '0' ? intl('SCREEN.MESSAGES.TITLE_NEW') : '')
  }`;

  const Header = currentState.isFactory ? (
    <FactoryHeader titleText={title} backRoute={PATH.MESSAGES} />
  ) : (
    <StoreHeader titleText={title} backRoute={PATH.MESSAGES} />
  );

  const initialValues = isFactory
    ? {
        name: settings?.messageFrom || user.name,
        email: settings?.messageEmail || user.email,
      }
    : {
        name: user.name,
        email: user.email,
      };

  return (
    <IonPageWrapper>
      {thread && (
        <>
          {Header}
          <IonContent>
            <PageWrapper maxWidth="800px">
              {(thread?.messages || []).map((message) => (
                <MessageBox
                  message={message}
                  contact={thread.contact}
                  isFactory={isFactory}
                  key={message.id}
                />
              ))}
              <Card className={`reply-container ${accessHide(ACCESS.ACTION.SUPPORT.REPLY)}`}>
                <Formik
                  innerRef={formRef}
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validate={validateForm}
                  onSubmit={(values, formik) => {
                    onSubmitEmail(values, formik);
                  }}>
                  {(formik) => {
                    const isFormValid = formik.isValid && !overMax;
                    return (
                      <>
                        <FormSection
                          title="SCREEN.MESSAGES.TITLE_REPLY"
                          formik={formik}
                          FORM={replyForm}
                          marginBottom="0"
                        />
                        <AttachmentForm className="attachform" files={files} onChange={onChangeAttachment} />
                        <Flex className="button-container">
                          <Button className="third" variant="outlined" onClick={() => history.goBack()}>
                            {intl('BUTTON.CANCEL')}
                          </Button>
                          <Button
                            className="third submit-button"
                            disabled={!isFormValid}
                            onClick={formik.handleSubmit}>
                            {intl('BUTTON.SUBMIT')}
                          </Button>
                        </Flex>
                      </>
                    );
                  }}
                </Formik>
              </Card>
            </PageWrapper>
          </IonContent>
        </>
      )}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  thread: state.thread.thread,
});

const mapDispatchToProps = {
  onHydrate: (params) => threadActions.fetchThread(parseInt(params.threadId), parseInt(params.messageId)),
  onDehydrate: threadActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MessageScreen));

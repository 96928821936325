import { toLocal } from '$gbusiness/helpers/date';
import ItemModel, { deriveRawToItem } from './item';
import ItemBatchModel, { deriveRawToItemBatch } from './itemBatch';
import WarehouseModel, { deriveRawToWarehouse } from './warehouse';

export default interface SerialModel {
  id: number;
  serial: string;
  storeId?: number;
  storeName?: string;
  status: string;
  batch?: ItemBatchModel;
  item?: ItemModel;
  note?: string;
  categories?: string;
  cats?: Array<any>;
  warehouse?: WarehouseModel;
  settings?: any;
  createdAt?: string;
}

export const deriveSerialStatus = (status) => {
  return status || 'NEW';
};

export const deriveCategories = (categories) => {
  const cats = (categories || []).map((c) => c.name);
  return cats.join(',');
};

export const deriveRawToSerial = (raw) => {
  return {
    id: raw.id,
    serial: raw.serial,
    storeId: raw.storeId || 0,
    storeName: raw.storeName || '',
    status: deriveSerialStatus(raw.status),
    categories: deriveCategories(raw.item?.categories),
    warehouse: deriveRawToWarehouse(raw.warehouse),
    note: raw.note || '',
    cats: raw.item?.categories || [],
    ...(raw.item && { item: deriveRawToItem(raw.item) }),
    ...(raw.batch && { batch: deriveRawToItemBatch(raw.batch, true) }),
    ...(raw.settings && { settings: Array.isArray(raw.settings) ? {} : raw.settings }),
    createdAt: toLocal(raw.createdAt),
  };
};

export const initialSerial: SerialModel = {
  id: 0,
  serial: '',
  status: '',
};

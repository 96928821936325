import { Attachment } from '@mui/icons-material';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToBroadcast } from '$fbusiness/models/broadcast';

export const BROADCAST_TABLE_CONFIG = (onClick): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.broadcast.general,
    method: 'GET',
    deriveToModel: deriveRawToBroadcast,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    onClickRow: onClick,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 1000,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

const BROADCAST_TABLE = (isFactory): Array<CellModel> => [
  {
    label: 'SCREEN.BROADCAST.COLS.ID',
    value: 'id',
    className: 'desktop',
    align: 'center',
    alwaysShow: true,
    width: 80,
  },
  {
    label: 'SCREEN.BROADCAST.COLS.STORE',
    value: 'store',
    isHidden: !isFactory,
    component: (row) => (
      <div className="small">
        {(row.stores || []).map((s, i) => (
          <span key={i}>{s.name}</span>
        ))}
      </div>
    ),
    alwaysShow: true,

    width: 250,
  },
  {
    label: 'SCREEN.BROADCAST.COLS.SUBJECT',
    value: 'subject',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.BROADCAST.COLS.FROM_EMAIL',
    value: 'fromEmail',
    // component: (row) => <>{row.fromEmail}</>,
    alwaysShow: true,
    width: 300,
  },
  {
    label: '',
    value: 'attachment',
    className: 'desktop',
    component: (row) => <>{row.files.length ? <Attachment style={{ color: '#999' }} /> : <></>}</>,
    width: 80,
  },
  {
    label: 'SCREEN.BROADCAST.COLS.DATETIME',
    value: 'createdAt',
    className: 'metadata',
    component: (row) => <>{row.createdAt}</>,
    alwaysShow: true,
    width: 250,
  },
  // {
  //   label: 'SCREEN.FILES.COLS.ACTION',
  //   value: '',
  //   className: 'action',
  //   align: 'center',
  //   component: (row, actions) => {
  //     return (
  //       <Button className="left" fullWidth onClick={e => actions.handlePopoverOpen(e, row)}>
  //         <MoreHoriz />
  //       </Button>
  //     );
  //   },
  //   width: 80,
  // },
];

export default BROADCAST_TABLE;

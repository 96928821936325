import React from 'react';
import { Table } from '$gcomponents/reusables';
import { REFUND_DETAILS_TABLE_CONFIG, REFUND_ITEMS_TABLE } from './refundsItemTable';

interface RefundsItemsListProps {
  refunds: Array<any>;
}

const RefundsItemsList: React.FC<RefundsItemsListProps> = ({ refunds }) => {
  const hasRefunds = refunds?.length > 0;
  if (!hasRefunds) return null;

  const refundItems = refunds.reduce((acc, refund) => {
    return [...acc, ...refund.items];
  }, []);

  const tableProps: any = hasRefunds
    ? {
        styles: {
          ...REFUND_DETAILS_TABLE_CONFIG.styles,
          cellPadding: ` 0px 0px`,
          rowHeight: 22,
          headerHeight: 28,
        },
      }
    : null;

  return (
    <>
      <h4>Refunds</h4>
      <Table {...tableProps} TABLE={REFUND_ITEMS_TABLE()} data={refundItems} />
      <br />
      <br />
    </>
  );
};

export default RefundsItemsList;

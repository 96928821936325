import styled from 'styled-components';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';

export const OrderSummaryWrapper = styled(Div)`
  padding: ${SPACE.MEDIUM} 0;

  .title {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.MEDIUM};
    padding: ${SPACE.MEDIUM} ${SPACE.MEDIUM};
  }
  .refund-table {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    > div {
      width: 100%;
    }
    .MuiTableCell-root {
      font-size: ${FONT.XSMALL};
    }
  }
  .box {
    padding: 0;
    padding: 0 ${SPACE.LARGE};
    .line {
      display: flex;
      justify-content: space-between;
      height: 24px;
      .label {
        width: 150px;
        padding: ${SPACE.SMALL};
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
      .value {
        width: 110px;
        padding: ${SPACE.SMALL};
        text-align: right;
      }
    }
    .line.total {
      > * {
        font-weight: ${WEIGHT.BOLD};
        font-size: ${FONT.LARGE};
      }
    }
  }
`;

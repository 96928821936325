import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Col, Div, Row, SPACE } from '$gstyles';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button, HeaderTitle } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import Header from '$components/header';
import { itemActions } from '$fbusiness/redux/item';
import ItemModel, { getMainImageAttr } from '$fbusiness/models/item';
import { initialValue as initialCategory } from '$fbusiness/models/category';

import {
  ITEM_FORM,
  ITEM_CATEGORY_FORM,
  ITEM_DEPT_FORM,
  ITEM_PHOTO_FORM,
  groomCats,
  E_ITEM_FORM,
} from './itemForm';
import { IonPageWrapper } from './styles';
import DepartmentModel from '$fbusiness/models/department';
import CategoryModel, { getCategoriesFromDepts } from '$fbusiness/models/category';
import { JPEG_TAG, PNG_TAG } from '$gbusiness/enums/general';
import { itemText, replaceAll, toNumber } from '$gbusiness/helpers/util';
import ModifierGroupModal from '../modifiers';
import ModifierGroupModel, { initialModifierGroup } from '$fbusiness/models/modifierGroup';
import { initialModifer } from '$fbusiness/models/modifier';
import { AppForm } from '$styles/general';
import { IconButton } from '@mui/material';
import VendorModel from '$fbusiness/models/vendor';
import TaxModel from '$fbusiness/models/tax';
import ImportModal from '../importModal';
import Footer from '$gcomponents/widgets/footer';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';
import FactoryModel from '$fbusiness/models/factory';
import CategoryTreeInput from '../../categoryScreens/categoryTreeInput';
import CategoryModal from '../../categoryScreens/categoryModal';
import { timestamp } from '$gbusiness/helpers/date';
import Img from '$gcomponents/primitives/img';
import PATH from '$business/enums/paths';
import SerialsSection from '../serialsScreen.tsx/section';
import BatchSection from '../batchesScreen/batchSection';
import HistorySection from '$fcomponents/historySection';
import CurrentStateModel from '$fbusiness/models/currentState';
import { accessDisable, accessHide, isAccessible } from '$fbusiness/helpers/util';
import { ACCESS } from '$fbusiness/enums/access';

interface ItemDetailsScreenProps {
  history: any;
  factory: FactoryModel;
  currentState: CurrentStateModel;
  item: ItemModel | null;
  allmgs: Array<ModifierGroupModel>;
  departments: Array<DepartmentModel>;
  categories: Array<CategoryModel>;
  vendors: Array<VendorModel>;
  taxes: Array<TaxModel>;
  saveItem: Function;
  fetchItems: Function;
  deleteModifierGroup: Function;
  isFinished: boolean;
}

const ItemDetailsScreen: React.FC<ItemDetailsScreenProps> = ({
  history,
  item,
  allmgs,
  factory,
  currentState,
  departments,
  categories,
  taxes,
  vendors,
  saveItem,
  deleteModifierGroup,
  isFinished,
}) => {
  const closeScreen = () => {
    history.push(PATH.ITEMS);
  };

  const serialRef = React.useRef<any>();
  const batchRef = React.useRef<any>();
  const historyRef = React.useRef<any>();
  const [checked, setChecked] = useState<Array<number>>((item?.categories || []).map((cat) => cat.id));
  const [modifierGroupIndex, setModifierGroupIndex] = useState<any>(null);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const formRef = useRef<any>();
  useEffect(() => {
    if (!isFinished) return;
    // fetchItems();
    history.push(PATH.ITEMS, { autoFetch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  useEffect(() => {
    if (formRef?.current) formRef.current.validateForm();
  }, [checked]);
  useEffect(() => {
    setChecked((item?.categories || []).map((cat) => cat.id));
  }, [item]);

  if (!item)
    return (
      <IonPageWrapper>
        <Header titleText={intl('ITEM.EDIT', { item: intl('COMMON.ITEM') })} />
        <IonContent></IonContent>
      </IonPageWrapper>
    );

  const { type, settings } = factory;
  const { subcategory } = settings;
  const { id, createdAt, depts, categories: cats, modifierGroups, updatedAt, image, ...initialValues } = item;
  const titleKey = id ? 'EDIT' : 'CREATE_NEW';
  const isEcommerce = type === FACTORY_TYPE.ECOMMERCE;
  const hasSubcategory = subcategory ? true : false;
  const otherImages = item?.files && item?.files?.length > 1 ? item?.files.filter((f) => !f.isMain) : [];

  const isEdit = !!id;
  const disableEditClass = accessDisable(isEdit ? ACCESS.ACTION.ITEM.EDIT : ACCESS.ACTION.ITEM.CREATE);

  const onSubmit = (values) => {
    const {
      image,
      deptId,
      rawCost,
      wholesalePrice,
      retailPrice,
      quantity,
      categoryIds,
      files,
      vendorId,
      ...newValues
    } = values;
    const groomedCats = hasSubcategory ? checked : groomCats(categories, values);
    saveItem(id, {
      ...newValues,
      ...(image?.length > 100 && { image: replaceAll(image, { [PNG_TAG]: '', [JPEG_TAG]: '' }) }),
      ...(!image && id && { deleteImg: true }),
      fileName: getMainImageAttr(files, 'fileName'),
      categories: groomedCats,
      rawCost: toNumber(rawCost) || 0,
      wholesalePrice: toNumber(wholesalePrice) || 0,
      retailPrice: toNumber(retailPrice) || 0,
      vendorId: vendorId === '0' || !vendorId ? null : vendorId,
      id,
      quantity,
      modifierGroups,
    }).then((succces) => {
      onRefreshHistory();
    });
  };

  const closeModifierModal = (changed) => {
    if (changed) formRef.current.setFieldValue('updated', true);
    setModifierGroupIndex(null);
  };

  const addBatch = () => {
    batchRef.current?.onEditBatch();
  };
  const addSerial = () => {
    serialRef.current?.onAddSerial();
  };
  const onRefreshHistory = (refreshHistory = true, refreshSerial = false) => {
    if (refreshHistory) historyRef.current?.refreshTable();
    if (refreshSerial) serialRef.current?.refreshTable();
  };

  const onEditModifierGroup = (i) => {
    setModifierGroupIndex(i);
  };

  const onDeleteModifierGroup = (index) => {
    formRef.current.setFieldValue('updated', true);
    deleteModifierGroup(index);
  };

  const onDepartmentUncheck = ({ formValues, newValue, value, setFieldValue }) => {
    if (!formValues.deptIds.includes(value)) return;
    const excludeIds = getCategoriesFromDepts(categories, [newValue]).map((c) => c.id);
    const filteredCats = formValues.categoryIds.filter((c) => !excludeIds.includes(c));
    setFieldValue('categoryIds', filteredCats);
  };

  const handleCategoryInput = (id) => {
    if (checked.includes(id)) setChecked(checked.filter((c) => c !== id));
    else setChecked([...checked, id]);
  };

  const validateForm = (values) => {
    const generalErrors = input.validateError(
      [
        ...(hasSubcategory ? [] : [...ITEM_CATEGORY_FORM([], values), ...ITEM_DEPT_FORM([], null)]),
        ...ITEM_PHOTO_FORM,
        ...ITEM_FORM(taxes, [], factory),
        ...(isEcommerce ? E_ITEM_FORM() : []),
      ],
      values,
    );
    if (!hasSubcategory) return generalErrors;
    return {
      ...generalErrors,
      ...(checked.length ? {} : { categoryIds: 'REQUIRED' }),
    };
  };

  const modifierGroup =
    modifierGroupIndex < 0
      ? {
          ...initialModifierGroup,
          itemId: id,
          modifiers: [initialModifer],
        }
      : modifierGroups
      ? modifierGroups[modifierGroupIndex]
      : initialModifierGroup;

  return (
    <IonPageWrapper>
      <Header titleText={intl('ITEM.' + titleKey, { item: intl('COMMON.ITEM') })} />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          ...initialValues,
          settings: initialValues?.settings || {},
          ...item,
          ...(isEcommerce && {}),
          image: image?.fileUrl,
          updatedAt: timestamp(updatedAt),
        }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <AppForm>
                <Row className="form-content" maxWidth="1300px">
                  <Col breakpoint="1180px" className="left flex-start" gridSize={6}>
                    <FormSection
                      className="form-section"
                      marginBottom="0"
                      title="SCREEN.ITEM.ITEM_INFO"
                      FORM={ITEM_FORM(taxes, vendors, factory)}
                      formik={formik}
                    />

                    <FormSection
                      className="form-section"
                      title="SCREEN.ITEM.ITEM_OPTIONS"
                      FORM={[]}
                      marginBottom="0"
                      formik={formik}>
                      <Div className="modifier-section">
                        {modifierGroups?.map((mg, i) => {
                          if (mg.deleted) return null;
                          const modifiers = mg.modifiers.filter((m) => !m.deleted).map((m) => m.name);
                          const modifiersStr = modifiers.join(', ');
                          return (
                            <Flex key={i} className="modifier-group">
                              <Flex>
                                <div className="mg-name">{mg.name}</div>
                                <div className="modifiers">({modifiersStr})</div>
                              </Flex>
                              <Flex>
                                <IconButton onClick={() => onEditModifierGroup(i)}>
                                  <EditOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => onDeleteModifierGroup(i)}>
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </Flex>
                            </Flex>
                          );
                        })}
                        <Flex marginTop={SPACE.LARGE} justifyContent="space-between">
                          <Button
                            startIcon={<AddIcon />}
                            size="small"
                            className={disableEditClass}
                            onClick={() => onEditModifierGroup(-1)}>
                            {itemText('add', 'options')}
                          </Button>
                          <Button
                            color="secondary"
                            startIcon={<GetAppOutlinedIcon />}
                            className={disableEditClass}
                            size="small"
                            onClick={() => setShowImportDialog(true)}>
                            {intl('BUTTON.IMPORT_OPTIONS')}
                          </Button>
                        </Flex>
                      </Div>
                    </FormSection>

                    {hasSubcategory ? (
                      <div className="form-section">
                        <HeaderTitle>
                          <div>{intl('SCREEN.ITEM.CATEGORIES')}</div>
                        </HeaderTitle>
                        {categories.map((cat) => (
                          <CategoryTreeInput
                            key={cat.id}
                            level={0}
                            category={cat}
                            checked={checked}
                            onChange={handleCategoryInput}
                          />
                        ))}
                        <Flex marginTop={SPACE.LARGE} justifyContent="flex-start">
                          <Button
                            startIcon={<AddIcon />}
                            size="small"
                            className={accessDisable(ACCESS.ACTION.CATEGORY.CREATE)}
                            onClick={() => setShowCategoryModal(true)}>
                            {itemText('add', 'Category')}
                          </Button>
                        </Flex>
                      </div>
                    ) : (
                      <>
                        <FormSection
                          title="SCREEN.ITEM.DEPARTMENTS"
                          className="form-section"
                          marginBottom="0"
                          FORM={ITEM_DEPT_FORM(departments, onDepartmentUncheck)}
                          formik={formik}
                        />
                        <FormSection
                          title="SCREEN.ITEM.CATEGORIES"
                          className="form-section"
                          marginBottom="0"
                          FORM={ITEM_CATEGORY_FORM(categories, formik.values)}
                          formik={formik}
                        />
                      </>
                    )}
                    <FormSection
                      title="SCREEN.ITEM.ITEM_PHOTO"
                      className="form-section"
                      marginBottom="0"
                      FORM={ITEM_PHOTO_FORM}
                      formik={formik}
                    />

                    {otherImages.length > 0 && (
                      <Flex className="other-images">
                        {otherImages.map((file, i) => (
                          <div className="thumb" key={i}>
                            {/* <Close className="cancel-button" /> */}
                            <Img
                              src={file.sizes?.small || ''}
                              showShadow
                              shadowSize={1}
                              height="48px"
                              width="48px"
                            />
                          </div>
                        ))}
                      </Flex>
                    )}
                    {isEcommerce && (
                      <FormSection
                        title="SCREEN.ITEM.E_COMMERCE"
                        className="form-section"
                        marginBottom="0"
                        FORM={E_ITEM_FORM()}
                        formik={formik}
                      />
                    )}
                  </Col>

                  <Col breakpoint="1180px" className="right flex-start" gridSize={6}>
                    {id && (
                      <PageWrapper>
                        <Flex justifyContent="space-between">
                          <HeaderTitle>{intl('SCREEN.HISTORY.TITLE_ITEM')}</HeaderTitle>
                        </Flex>
                        <HistorySection ref={historyRef} itemId={item.id} />
                        {settings?.serial && isAccessible(ACCESS.SCREEN.SERIAL, currentState) && (
                          <>
                            <Flex justifyContent="space-between">
                              <HeaderTitle>{intl('SCREEN.SERIAL.TITLE')}</HeaderTitle>
                              <Button
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={addSerial}
                                className={accessDisable(ACCESS.ACTION.SERIAL.CREATE)}>
                                {itemText('ADD_NEW', 'Serial')}
                              </Button>
                            </Flex>
                            <SerialsSection
                              ref={serialRef}
                              onRefresh={onRefreshHistory}
                              className={accessHide(ACCESS.SCREEN.SERIAL)}
                              factory={factory}
                              item={item}
                              isItemPage
                            />
                          </>
                        )}

                        {settings?.batch && isAccessible(ACCESS.SCREEN.RECEIVING, currentState) && (
                          <>
                            <Flex justifyContent="space-between">
                              <HeaderTitle>{intl('SCREEN.BATCH.TITLE')}</HeaderTitle>
                              <Button
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={addBatch}
                                className={accessDisable(ACCESS.ACTION.RECEIVING.CREATE)}>
                                {itemText('ADD', 'Receiving')}
                              </Button>
                            </Flex>
                            <BatchSection
                              ref={batchRef}
                              onRefresh={() => onRefreshHistory(true, true)}
                              factory={factory}
                              item={item}
                              isItemPage
                            />
                          </>
                        )}
                      </PageWrapper>
                    )}
                  </Col>
                </Row>
              </AppForm>
            </IonContent>
            <Footer justifyContent="space-around">
              <Button variant="outlined" onClick={closeScreen}>
                {intl('BUTTON.CANCEL')}
              </Button>
              {/* {settings?.batch && (
                <Button variant="outlined" onClick={addBatch}>
                  {itemText('CREATE_NEW', intl('RECEIVING'))}
                </Button>
              )} */}
              <Button
                disabled={!formik.isValid || (!hasSubcategory && !formik.dirty)}
                color="primary"
                className={`submit-button ${disableEditClass}`}
                variant="contained"
                onClick={formik.handleSubmit}>
                {intl('BUTTON.SUBMIT')}
              </Button>
            </Footer>
          </>
        )}
      </Formik>
      {modifierGroup && (
        <ModifierGroupModal
          index={modifierGroupIndex}
          onClose={closeModifierModal}
          modifierGroup={modifierGroup}
        />
      )}
      <ImportModal
        modifierGroups={allmgs || []}
        onImport={() => formRef.current.setFieldValue('updated', true)}
        show={showImportDialog}
        onClose={() => setShowImportDialog(false)}
      />
      <CategoryModal
        factory={factory}
        category={initialCategory}
        categories={categories}
        show={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  item: state.item.item,
  allmgs: state.item.modifierGroups,
  vendors: state.factory.factory.vendors,
  taxes: state.factory.factory.taxes,
  departments: state.department.departments,
  categories: state.category.categories,
  isFinished: state.item.isFinished,
});

const mapDispatchToProps = {
  onHydrate: (params) => itemActions.fetchItem(parseInt(params.itemId)),
  deleteModifierGroup: itemActions.deleteModifierGroup,
  onDehydrate: itemActions.dehydrate,
  saveItem: itemActions.saveItem,
  fetchItems: itemActions.fetchItems,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ItemDetailsScreen));

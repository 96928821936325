import React from 'react';
import { connect } from 'react-redux';
import { IonPage } from '@ionic/react';

import intl from '$gintl';
import UserModel from '$gbusiness/models/user';
import { userActions } from '$fbusiness/redux/user';
import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import FormView from './formView';
import FactoryModel from '$fbusiness/models/factory';

interface UserScreenProps {
  onHydrate: Function;
  onDehydrate: Function;
  saveUser: Function;
  match: any;
  roles: Array<any>;
  factory: FactoryModel;
  myUserId: number;
  user: UserModel;
  currentState: any;
}

const UserScreen: React.FC<UserScreenProps> = React.memo(
  ({ user, saveUser, myUserId, match, roles: allRoles, factory, currentState }) => {
    const {
      params: { userId },
    } = match;
    const isNewUser = !parseInt(userId);
    const title = isNewUser
      ? intl('SCREEN.USER.TITLE_NEW')
      : intl('SCREEN.USER.TITLE', { firstName: user?.firstName, lastName: user?.lastName });

    const onSaveUser = (userId, values) => {
      const { password, firstName, lastName, roleId, email, settings } = values;
      const params = {
        firstName,
        lastName,
        userLevel: roleId,
        email,
        ...(password && { password }),
        settings,
      };
      saveUser(userId, params);
    };

    const currentUserLevel = currentState.user?.userLevel === 0 ? 0 : currentState.user?.userLevel || 3;
    const roles = allRoles.filter((r) => r.level >= currentUserLevel);

    return (
      <IonPage>
        <Header titleText={title} />
        <FormView
          factory={factory}
          currentState={currentState}
          user={user}
          roles={roles}
          isMe={user?.userId === myUserId}
          onSubmit={onSaveUser}
        />
      </IonPage>
    );
  },
  (pp, np) => pp.user === np.user,
);

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  roles: state.factory.roles,
  user: state.user.user,
  myUserId: state.currentUser.userId,
});

const mapDispatchToProps = {
  onHydrate: (params) => userActions.fetchUser(parseInt(params.userId)),
  saveUser: (userId, params) => userActions.saveUser(parseInt(userId), params),
  onDehydrate: () => userActions.dehydrate(),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(UserScreen));

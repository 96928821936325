import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { Header } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import BatchSection from './batchSection';
import { itemActions } from '$fbusiness/redux/item';
import { screen } from '$fcomponents/hoc';
import PATH from '$business/enums/paths';

interface BatchesScreenProps {
  onHydrate;
  currentState;
  factory: FactoryModel;
  items;
  match;
}

const BatchesScreen: React.FC<BatchesScreenProps> = ({ factory, currentState, items, match }) => {
  const { path } = match;
  const isExpiration = path.includes(PATH.EXPIRATION);

  const title = isExpiration ? 'SCREEN.BATCH.TITLE_EXPIRATION' : 'SCREEN.BATCH.TITLE';
  return (
    <IonPageWrapper>
      <Header title={title} />
      <IonContent>
        <BatchSection
          isExpiration={isExpiration}
          currentState={currentState}
          factory={factory}
          items={items}
        />
      </IonContent>
      {/* <FabButton onClick={onEditBatch} /> */}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  items: state.item.list,
});

const mapDispatchToProps = {
  onHydrate: () => itemActions.fetchItemList(),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(BatchesScreen));

import BroadcastModel from '../../models/broadcast';

export const BROADCAST_FAILURE = 'BROADCAST_FAILURE';
export const FETCH_BROADCAST_SUCCESS = 'FETCH_BROADCAST_SUCCESS';
export const FETCH_BROADCASTS_SUCCESS = 'FETCH_BROADCASTS_SUCCESS';
export const UPDATE_BROADCAST_SUCCESS = 'UPDATE_BROADCAST_SUCCESS';
export const CLEAN_BROADCAST = 'CLEAN_BROADCAST';

// Action Types
export interface failureType {
  type: typeof BROADCAST_FAILURE;
  err: string;
}

export interface cleanType {
  type: typeof CLEAN_BROADCAST;
}

export interface FetchBroadcastSuccessType {
  type: typeof FETCH_BROADCAST_SUCCESS | typeof UPDATE_BROADCAST_SUCCESS;
  broadcast: BroadcastModel;
}

export interface FetchBroadcastsSuccessType {
  type: typeof FETCH_BROADCASTS_SUCCESS;
  broadcasts: Array<BroadcastModel>;
}

export interface BroadcastReducerType {
  broadcasts: Array<BroadcastModel>;
  broadcast?: BroadcastModel | null;
}

export type BroadcastActionTypes =
  | failureType
  | cleanType
  | FetchBroadcastSuccessType
  | FetchBroadcastsSuccessType;

export const BROADCAST_INIT_STATE: BroadcastReducerType = {
  broadcasts: [],
  broadcast: null,
};

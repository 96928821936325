import React from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { ColAuto, Row, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import BroadcastModel from '$fbusiness/models/broadcast';
import { MessageBoxWrapper } from '../../messageScreens/messageScreen/styles';
import { Box, Divider } from '@mui/material';
import { DATE_FORMATS } from '$gbusiness/enums';
import { format } from '$gbusiness/helpers/date';
import { GetApp } from '@mui/icons-material';

interface BroadcastModalProps {
  broadcast: BroadcastModel;
  show: boolean;
  onClose: Function;
}

const BroadcastModal: React.FC<BroadcastModalProps> = ({ show, onClose, broadcast }) => {
  return (
    <Modal
      title="SCREEN.BROADCAST.TITLE_DETAILS"
      titleText={broadcast?.subject || ''}
      show={show}
      onClose={() => onClose()}
      useCustom>
      <IonContent>
        {broadcast && (
          <MessageBoxWrapper className={``}>
            <Box className={`admin`}>
              <Row>
                <ColAuto>
                  <span className="caption">From </span>
                  <span className="name">{broadcast.fromName}</span>
                  <span>&lt;{broadcast.fromEmail}&gt;</span>
                </ColAuto>
                <ColAuto className="date right">
                  <span>Sent at {format(broadcast.createdAt, DATE_FORMATS.DATETIME)}</span>
                </ColAuto>
              </Row>
            </Box>
            <Divider />
            <Box
              className="content"
              style={{ minHeight: '200px' }}
              dangerouslySetInnerHTML={{ __html: broadcast.body.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
            />
            {(broadcast.files || []).length > 0 && (
              <>
                <Divider />
                <Flex className="attached-files">
                  {(broadcast.files || []).map((f, i) => {
                    if (!f.isImage) {
                      return (
                        <a className="file" key={i} href={f.fileUrl} download>
                          <Flex>
                            <span>{f.fileName}</span> <GetApp color="secondary" />
                          </Flex>
                        </a>
                      );
                    }
                    return (
                      <a key={i} href={f.fileUrl} target="_blank" rel="noreferrer">
                        <img src={f?.sizes?.small || f.fileUrl} alt={f.fileName} />
                      </a>
                    );
                  })}
                </Flex>
              </>
            )}
          </MessageBoxWrapper>
          // <>
          //   <Flex padding={SPACE.MEDIUM} className="from">
          //     <div className="fromname">{broadcast.fromName}</div>
          //     <div className="fromemail">&lt;{broadcast.fromEmail}&gt;</div>
          //   </Flex>
          // </>
        )}
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button className="third" onClick={() => onClose()}>
            {intl('BUTTON.CLOSE')}
          </Button>
        </Flex>
      </IonFooter>
    </Modal>
  );
};

export default BroadcastModal;

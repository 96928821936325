import React from 'react';

import { ModalWrapper } from './styles';

import DiscountModel from '$fbusiness/models/discount';
import DiscountDetails from './discountDetails';

interface DiscountEditModalProps {
  show: boolean;
  onClose: Function;
  discount: DiscountModel;
  currentState;
}

const DiscountEditModal: React.FC<DiscountEditModalProps> = ({ show, discount, onClose, currentState }) => {
  const titleKey = 'SCREEN.DISCOUNTS.TITLE_' + (discount?.id ? 'EDIT' : 'NEW');

  return (
    <ModalWrapper title={titleKey} show={show} onClose={() => onClose()} useCustom>
      {discount && <DiscountDetails onClose={onClose} currentState={currentState} discount={discount} />}
    </ModalWrapper>
  );
};

export default DiscountEditModal;

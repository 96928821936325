import React from 'react';
import { MessageModel } from '$fbusiness/models/thread';
import { MessageBoxWrapper } from './styles';
import { Box, Button, Divider } from '@mui/material';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { GetApp } from '@mui/icons-material';
import { Flex } from '$gstyles/wrapper';
import { emailLink, phoneLink } from '$fcomponents/helpers/utils';
import { ADMIN_MEMO_FORM } from './replyForm';
import FormikForm from '$gcomponents/reusables/formikForm';
import { useDispatch } from 'react-redux';
import { threadActions } from '$fbusiness/redux/thread';

interface MessageBoxProps {
  message: MessageModel;
  isFactory: boolean;
  contact?: any;
}

const MessageBox: React.FC<MessageBoxProps> = ({ message, isFactory, contact: threadContact }) => {
  const dispatch = useDispatch();
  const { id, content, adminMemo, admin, user, createdAt, isRead, files } = message;
  const unread = (isFactory && user && !isRead) || (!isFactory && admin && !isRead);
  const author = admin || user;

  const contact = admin ? null : threadContact;
  const adminMemoForm = isFactory ? ADMIN_MEMO_FORM() : null;
  const initialValues = { adminMemo: adminMemo };

  const onSubmitAdminMemo = (values) => {
    dispatch(threadActions.updateMessage(id, { adminMemo: values.adminMemo }));
  };

  return (
    <MessageBoxWrapper className={`${unread ? 'unread' : ''}`}>
      <Box className={`posted ${admin ? 'admin' : 'user'}`}>
        <span className="caption">Posted by</span> <span className="name">{author.name}</span> on
        <span className="date"> {format(createdAt, DATE_FORMATS.DATETIME)}</span>
        {contact && (
          <div className="contact">
            <Flex className="phone">{phoneLink(contact.phone, false, true)}</Flex>
            <Flex className="email">{emailLink(contact.email, false, true)}</Flex>
          </div>
        )}
      </Box>
      <Divider />
      <Box
        className="content"
        dangerouslySetInnerHTML={{ __html: content.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
      />
      {!!adminMemoForm && (
        <Box className="admin-memo-container">
          <FormikForm
            myForm={adminMemoForm}
            initialValues={initialValues}
            onSubmit={onSubmitAdminMemo}
            lastColumn={<Button variant="outlined">Save</Button>}
          />
        </Box>
      )}
      {(files || []).length > 0 && (
        <>
          <Divider />
          <Flex className="attached-files">
            {(files || []).map((f, i) => {
              if (!f.isImage) {
                return (
                  <a className="file" key={i} href={f.fileUrl} download>
                    <Flex>
                      <span>{f.fileName}</span> <GetApp color="secondary" />
                    </Flex>
                  </a>
                );
              }
              return (
                <a key={i} href={f.fileUrl} target="_blank" rel="noreferrer">
                  <img src={f?.sizes?.small || f.fileUrl} alt={f.fileName} />
                </a>
              );
            })}
          </Flex>
        </>
      )}
    </MessageBoxWrapper>
  );
};

export default MessageBox;

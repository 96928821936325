import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, defaultTable, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';
import { Button, Link } from '$gcomponents/primitives';
import { deriveRawToInvoice, deriveRawToInvoiceForInner } from '$fbusiness/models/invoice';
import {
  CLOSE_OPTIONS,
  OPEN_OPTIONS,
  KEYS as INVOICE_STATUS,
  CANCELLED_OPTIONS,
  VOID_PAYMENT_OPTIONS,
  REFUNDED_OPTIONS,
  convertToOption,
} from '$fbusiness/enums/options/invoiceStatus';
import { Select, Button as MuiButton } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { MoreVert } from '@mui/icons-material';
import ExportModel from '$gbusiness/models/export';
import { CREDIT, INVOICE, PAYMENT, REFUND, REFUND_CREDIT } from '$fbusiness/enums/transactionType';
import {
  deriveAmount,
  deriveDeliveryDate,
  deriveDueDate,
  deriveInfo,
  deriveInvoiceField,
  deriveInvoiceNo,
  deriveOpenBalance,
  deriveStatus,
  creditStatusPicker,
} from '../transactionsScreen/utils';
import { toRefundId } from '$fbusiness/models/refund';
import { deriveRawToTransaction } from '$fbusiness/models/transaction';
import {
  accessDisable,
  accessHide,
  deriveDate,
  deriveTransactionType,
  getInvoice,
  getSalesman,
  isAccessible,
} from '$fbusiness/helpers/util';
import { FONT } from '$gstyles';
import PopOver from '$gcomponents/reusables/popOver';
import { ACCESS } from '$fbusiness/enums/access';

export const invoiceExportSettings = (columns, isBalanceMode = false, isTransaction = false): ExportModel => {
  const cols: any = columns.reduce((acc, c) => ((acc[c] = true), acc), {}); // eslint-disable-line no-sequences
  return {
    url: isBalanceMode ? configs.api.transaction.list : configs.api.invoice.general,
    method: isBalanceMode ? 'POST' : 'GET',
    deriveToModel: isBalanceMode ? deriveRawToTransaction : deriveRawToInvoice,
    deriveTable: (row) => ({
      // id: row.id,
      store: row.store?.name || '',
      ...(cols['store.code'] && { AccountNo: row.store?.code || '' }),
      ...(isBalanceMode && { type: deriveTransactionType(row) }),
      ...(cols['createdAt'] && { date: deriveDate(row) }),
      invoiceNo: deriveInvoiceNo(row),
      ...(cols['order.status'] && { orderStatus: deriveInvoiceField(row, 'order.status') }),
      ...(cols['order.orderNumber'] && { orderNo: deriveInvoiceField(row, 'order.orderNumber') }),
      ...(cols['invoiceType.name'] && { invoiceType: row.invoiceType?.name || '' }),
      ...(cols['detailedData.poNo'] && { poNo: deriveInvoiceField(row, 'detailedData.poNo') }),
      ...(cols['status'] || (!isTransaction && { status: deriveStatus(row) })),
      ...(cols['dueDate'] && { dueDate: deriveDueDate(row) }),
      ...(cols['paymentTerm'] && { paymentTerm: deriveInvoiceField(row, 'term.name') }),
      ...(cols['order.deliveryDate'] && { deliveryDate: deriveDeliveryDate(row) }),
      ...(cols['salesRep'] && { salesRep: deriveInvoiceField(row, 'salesRep') }),
      ...(cols['qtySent'] && { qtySent: deriveInvoiceField(row, 'qtySent') }),
      ...(cols['qtyReceived'] && { qtyReceived: deriveInvoiceField(row, 'qtyReceived') }),
      ...(cols['order.city'] && { shipTo: deriveInvoiceField(row, 'order.city') }),
      ...(cols['total'] && { total: deriveAmount(row) }),
      ...(cols['balance'] && { balance: deriveOpenBalance(row) }),
      ...(cols['noteFactory'] && { factoryNote: deriveInvoiceField(row, 'noteFactory') }),
      ...(cols['author'] && { author: deriveInvoiceField(row, 'author.name') }),
    }),
    sortKey: 'createdAt',
    sortOrder: SORT.DSC,
  };
};

export const INVOICE_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.invoice.general,
    // mockData,
    deriveToModel: deriveRawToInvoiceForInner,
    method: 'GET',
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
    autoRefreshInterval: 30,
    innerKey: 'payments',
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: FONT.SMALL,
    minWidth: 800,
    maxWidth: 1400,
    cellPadding: ` 2px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 40,
    headerHeight: 56,
    setRowClass: (row) => {
      if (row.balance <= 0 && row.status === INVOICE_STATUS.PAID) return 'success';
      else if (row.status === INVOICE_STATUS.CANCELLED) return 'void';
      else if (row.status === INVOICE_STATUS.REFUNDED) return 'cancelled';
      else if (row.overdue) return 'overdue';
      else if (row.paymentGroup?.rejectedAt) return 'cancelled';
      return '';
    },
  },
  innerConfig: {
    ...defaultTable,
    dataSource: {
      ...defaultTable.dataSource,
      defaultSortKey: 'paymentDate',
      defaultSortOrder: SORT.DSC,
    },
    styles: {
      ...defaultStyles,
      minWidth: 800,
      maxWidth: 1000,
      fontSize: FONT.XSMALL,
      cellPadding: '2px 2px',
      isStripped: false,
      rowHeight: 26,
      headerHeight: 30,
      setRowClass: (row) => {
        if (row.isRefund || !!row?.rejected) return 'refund';
        return '';
      },
    },
  },
};

export const defaultInvoiceColumns = ['transactionTime', 'invoiceNo', 'order.status', 'total', 'balance'];

export const getDefaultInvoiceColumns = (isTransaction) => [
  ...defaultInvoiceColumns,
  ...(isTransaction ? ['status'] : []),
];

export const INVOICE_TABLE = ({
  onViewInvoice,
  onChangeStatus,
  onReceivePayment,
  onClickStore,
  isPickupOnly = false,
  isEcommerce = false,
  deleteInvoice = false,
  onAddCredit = (e) => {},
  onEditRefund = (e) => {},
  onDeleteRefund = (e) => {},
  onViewRefund = (e) => {},
  onViewPaymentGroup = (e, f) => {},
  onChangeRefundStatus = (e, f) => {},
  onRejectPayment = (e) => {},
  onAddCharge = (e) => {},
  isBalanceMode = false,
  isTransaction = false,
  currentState,
}): Array<CellModel> => [
  {
    label: 'SCREEN.INVOICES.COLS.STORE',
    value: 'store.name',
    alwaysShow: true,
    component: (row) => (
      <div className="link secondary" onClick={() => onClickStore(null, row.store)}>
        {row.store?.name}
      </div>
    ),
    // sortKey: 'store.name',
    // sortable: SORT.ASC,
    width: 230,
  },
  {
    isHidden: !isBalanceMode,
    label: 'SCREEN.TRANSACTIONS.COLS.TYPE',
    value: 'type',
    component: (row) => {
      return <>{deriveTransactionType(row)}</>;
    },
    alwaysShow: true,
    align: 'center',
    // sortable: SORT.ASC,
    width: 160,
  },
  {
    isHidden: !isBalanceMode,
    label: 'SCREEN.TRANSACTIONS.COLS.INFO',
    value: 'info',
    alwaysShow: true,
    align: 'left',
    component: (row) => <>{deriveInfo(row)}</>,
    width: 280,
  },
  {
    label: 'SCREEN.INVOICES.COLS.ACCOUNTNO',
    value: 'store.code',
    component: (row) => <>{row.store?.code || ''}</>,
    width: 180,
  },
  {
    label: 'SCREEN.INVOICES.COLS.CREATED',
    value: isBalanceMode ? 'transactionTime' : 'createdAt',
    component: (row) => <>{deriveDate(row)}</>,
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.INVOICES.COLS.INVOICE_NO',
    value: 'invoiceNumber',
    alwaysShow: true,
    component: (row) => {
      const inv = getInvoice(row);
      const invoiceNo = deriveInvoiceNo(row);
      if (inv) {
        return isAccessible(ACCESS.ACTION.INVOICE.VIEW, currentState) ? (
          <div className="link" onClick={() => onViewInvoice(inv)}>
            {inv?.invoiceNumber || ''}
          </div>
        ) : (
          inv?.invoiceNumber || ''
        );
      }
      if (row.type === PAYMENT) return invoiceNo;
      return (
        <div>
          {(row.type === INVOICE || row.type === REFUND) && (
            <>
              {isAccessible(ACCESS.ACTION.INVOICE.VIEW, currentState) ? (
                <div className="link" onClick={() => onViewInvoice(row.invoice)}>
                  {inv?.invoiceNumber || ''}
                </div>
              ) : (
                inv?.invoiceNumber || ''
              )}
            </>
          )}
          {(row.type === REFUND || row.type === REFUND_CREDIT) && (
            <>
              {isAccessible(ACCESS.ACTION.REFUND.VIEW, currentState) ? (
                <div className="link" onClick={() => onViewRefund(row.refund || row.credit)}>
                  {invoiceNo || ''}
                </div>
              ) : (
                invoiceNo || ''
              )}
            </>
          )}
          {row.type === CREDIT && row.credit?.simple && <div>{invoiceNo}</div>}
          {row.type === CREDIT && !row.credit?.simple && (
            <>
              {isAccessible(ACCESS.ACTION.REFUND.VIEW, currentState) ? (
                <div className="link" onClick={() => onViewRefund(row.credit)}>
                  {invoiceNo || ''}
                </div>
              ) : (
                invoiceNo || ''
              )}
            </>
          )}
        </div>
      );
    },
    className: 'main',
    sortable: isBalanceMode ? undefined : SORT.ASC,
    width: 200,
  },
  {
    isHidden: isBalanceMode,
    label: 'SCREEN.INVOICES.COLS.INVOICE_TYPE',
    value: 'invoiceType.name',
    align: 'left',
    component: (row) => <>{row?.invoiceType?.name || ''}</>,
    width: 160,
  },
  {
    label: 'SCREEN.INVOICES.COLS.ORDERNO',
    value: 'order.orderNumber',
    // component: (row) => displayRow(row, isBalanceMode, 'orderNumber'),
    component: (row) => <>{deriveInvoiceField(row, 'order.orderNumber')}</>,
    className: 'desktop',
    width: 140,
  },
  {
    label: 'SCREEN.INVOICES.COLS.PO_NO',
    value: 'detailedData.poNo',
    component: (row) => <>{deriveInvoiceField(row, 'detailedData.poNo')}</>,
    className: 'desktop',
    width: 140,
  },
  {
    label: 'SCREEN.INVOICES.COLS.ORDER_STATUS',
    value: 'order.status',
    component: (row) => <>{deriveInvoiceField(row, 'order.status')}</>,
    align: 'center',
    width: 180,
  },
  {
    label: 'SCREEN.INVOICES.COLS.' + (isPickupOnly ? 'PICKUP_DATE' : 'DUE_DATE'),
    value: 'dueDate',
    component: (row) => <>{deriveDueDate(row)}</>,
    sortable: isBalanceMode ? undefined : SORT.ASC,
    sortKey: isBalanceMode ? undefined : 'dueDate',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.TERM',
    value: 'paymentTerm',
    // component: (row) => displayRow(row, isBalanceMode, 'term.name'),
    component: (row) => <>{deriveInvoiceField(row, 'term.name')}</>,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.DELIVERY_DATE',
    value: 'order.deliveryDate',
    // component: (row) => displayRow(row, isBalanceMode, null, (inv) => <>{deriveDeliveryDate(row)}</>),
    component: (row) => <>{deriveDeliveryDate(row)}</>,
    className: 'desktop',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.SALES_REP',
    value: 'salesRep',
    // component: (row) => displayRow(row, isBalanceMode, null, (row) => <>{getSalesman(row)}</>),
    component: (row) => <>{getSalesman(row)}</>,
    width: 220,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_SENT',
    value: 'qtySent',
    className: 'desktop',
    align: 'center',
    // component: (row) => displayRow(row, isBalanceMode, 'qtySent'),
    component: (row) => <>{deriveInvoiceField(row, 'qtySent')}</>,
    // sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_RECEIVED',
    value: 'qtyReceived',
    // component: (row) => displayRow(row, isBalanceMode, 'qtyReceived'),
    component: (row) => <>{deriveInvoiceField(row, 'qtyReceived')}</>,
    className: 'desktop',
    align: 'center',
    // sortable: SORT.ASC,
    width: 150,
  },
  // {
  //   label: 'SCREEN.INVOICES.COLS.QTY_STATUS',
  //   value: '',
  //   component: row => <>{`${row.qtyReceived}/${row.qtySent}`}</>,
  //   align: 'center',
  //   width: 180,
  // },
  {
    isHidden: isPickupOnly,
    label: 'SCREEN.INVOICES.COLS.SHIP_CITY',
    value: 'order.city',
    // component: (row) => displayRow(row, isBalanceMode, 'order.city'),
    component: (row) => <>{deriveInvoiceField(row, 'order.city')}</>,
    className: 'desktop',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.TOTAL',
    value: 'total',
    isHidden: !isAccessible(ACCESS.MONEY.VIEW, currentState),
    // component: (rowtemp) => displayRowCb(rowtemp, isBalanceMode, null, (row) => <>{currency(deriveAmount(rowtemp))}</>),
    component: (row) => <>{currency(deriveAmount(row))}</>,
    // alwaysShow: true,
    sortable: isBalanceMode ? undefined : SORT.DSC,
    sortKey: isBalanceMode ? undefined : 'total',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.BALANCE',
    value: 'balance',
    isHidden: !isAccessible(ACCESS.MONEY.VIEW, currentState),
    component: (row) => <>{currency(deriveOpenBalance(row))}</>,
    sortable: isBalanceMode ? undefined : SORT.DSC,
    sortKey: isBalanceMode ? undefined : 'balance',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.PO_NOTE',
    value: 'noteFactory',
    className: 'desktop',
    // component: (row) => displayRow(row, isBalanceMode, 'noteFactory'),
    component: (row) => <>{deriveInvoiceField(row, 'noteFactory')}</>,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.AUTHOR',
    value: 'author.name',
    // component: (row) => displayRow(row, isBalanceMode, 'author.name'),
    component: (row) => <>{deriveInvoiceField(row, 'author.name')}</>,
    className: 'desktop',
    width: 200,
  },
  {
    isHidden: isBalanceMode,
    label: '',
    value: '',
    className: 'action vertical',
    alwaysShow: true,
    component: (row) => (
      <>
        <Button fullWidth size="small" variant="text" onClick={() => onViewInvoice(row)}>
          {intl('SCREEN.INVOICES.ACTIONS.VIEW_INVOICE')}
        </Button>
        {/* {row.status === 'FLAGGED' && (
          <Button fullWidth size="small" variant="text" onClick={() => regenerateInvoice(row)}>
            {intl('SCREEN.INVOICES.ACTIONS.CREATE_INVOICE')}
          </Button>
        )} */}
      </>
    ),
    minWidth: 64,
    width: 100,
  },
  {
    label: 'SCREEN.ORDERS.COLS.STATUS',
    value: 'status',
    alwaysShow: isTransaction ? false : true,
    className: 'no-label receive-cell',
    component: (row) => {
      const inv = getInvoice(row);
      if (row.type === CREDIT) {
        return creditStatusPicker(row, onChangeRefundStatus);
      }
      if (!inv) {
        return <>{deriveStatus(row)}</>;
      }
      let options;
      switch (inv.status) {
        case INVOICE_STATUS.CANCELLED:
          options = CANCELLED_OPTIONS;
          break;
        case INVOICE_STATUS.VOID_PAYMENT:
          options = VOID_PAYMENT_OPTIONS;
          break;
        case INVOICE_STATUS.PAID:
          options = CLOSE_OPTIONS;
          break;
        case INVOICE_STATUS.REFUNDED:
          options = REFUNDED_OPTIONS;
          break;
        default:
          options = [
            ...OPEN_OPTIONS,
            ...(inv.paidAmount > 0 || inv.creditAmount > 0
              ? [INVOICE_STATUS.VOID_PAYMENT].map(convertToOption)
              : []),
            ...(inv.balance <= 0 ? [INVOICE_STATUS.PAID].map(convertToOption) : []),
            ...(deleteInvoice ? [INVOICE_STATUS.DELETE].map(convertToOption) : []),
          ];
          break;
      }
      return (inv.closedAt || isEcommerce) &&
        isAccessible(ACCESS.ACTION.INVOICE.CHANGE_STATUS, currentState) ? (
        <>
          <Select
            variant="outlined"
            className="xsmall"
            value={inv.status}
            onChange={(e) => onChangeStatus(inv, e.target.value)}>
            {generateSelectOptions(options, inv.status, !!inv?.payments?.length)}
          </Select>
        </>
      ) : (
        <div>{deriveStatus(row)}</div>
      );
    },
    sortable: isBalanceMode ? undefined : SORT.ASC,
    minWidth: 136,
    width: 150,
  },
  {
    label: '',
    value: '',
    align: 'center',
    className: 'action',
    alwaysShow: true,
    component: (row) => {
      const inv = getInvoice(row);
      return (
        <>
          {inv.status === INVOICE_STATUS.OPEN && (
            <Button
              className={`multiline payment-button ${accessHide(ACCESS.ACTION.PAYMENT.CREATE)}`}
              size="small"
              onClick={() => onReceivePayment(row)}
              color="secondary">
              {intl('BUTTON.RECEIVE_PAYMENT')}
            </Button>
          )}
          {row.type === REFUND && (
            <Button
              className={accessHide(ACCESS.ACTION.REFUND.VIEW)}
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => onViewRefund(row.refund)}>
              {intl('SCREEN.REFUNDS.COLS.DETAILS')}
            </Button>
          )}
          {row.type === CREDIT && !row.credit?.simple && (
            <Button
              color="secondary"
              variant="contained"
              className={accessHide(ACCESS.ACTION.CREDIT.VIEW)}
              size="small"
              onClick={() => onViewRefund(row.credit)}>
              {intl('SCREEN.REFUNDS.COLS.DETAILS')}
            </Button>
          )}
          {row.type === PAYMENT && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              className={accessHide(ACCESS.ACTION.PAYMENT.VIEW)}
              onClick={() => onViewPaymentGroup(row.paymentGroup, row.store)}>
              {intl('SCREEN.REFUNDS.COLS.DETAILS')}
            </Button>
          )}
          {row.type === CREDIT && row.refund && (
            <Link
              className={`link w100 ${accessHide(ACCESS.ACTION.CREDIT.VIEW)}`}
              onClick={() => onViewRefund(row)}>
              {toRefundId(row.refund.id)}
            </Link>
          )}
        </>
      );
    },
    minWidth: 80,
    width: 150,
  },
  {
    label: '',
    value: '',
    align: 'center',
    className: 'action',
    alwaysShow: true,
    component: (row, actions) => {
      const inv = getInvoice(row);
      const refund = isBalanceMode ? row?.refund || row?.credit : null;
      const isCredit = row?.credit;
      const { settings } = currentState;
      return (
        <>
          {inv && (
            <MuiButton
              className="left ellipsis"
              fullWidth
              onClick={(e) => actions.handlePopoverOpen(e, { ...inv })}>
              <MoreVert />
            </MuiButton>
          )}
          {refund && !refund.untouchable && (
            <PopOver
              component={
                <MuiButton className="left ellipsis" fullWidth>
                  <MoreVert className="pointer" />
                </MuiButton>
              }>
              <>
                <Button
                  variant="text"
                  className={`left ${accessDisable(
                    isCredit ? ACCESS.ACTION.CREDIT.EDIT : ACCESS.ACTION.REFUND.EDIT,
                  )}`}
                  fullWidth
                  onClick={() => onEditRefund(refund)}>
                  {intl('BUTTON.EDIT')}
                </Button>
                <Button
                  variant="text"
                  className={`left ${accessDisable(
                    isCredit ? ACCESS.ACTION.CREDIT.DELETE : ACCESS.ACTION.REFUND.DELETE,
                  )}`}
                  fullWidth
                  onClick={() => onDeleteRefund(refund)}>
                  {intl('BUTTON.DELETE')}
                </Button>
                {settings?.customCredit && (
                  <Button
                    variant="text"
                    className={`left ${accessDisable(ACCESS.ACTION.CREDIT.CREATE)}`}
                    fullWidth
                    onClick={() => onAddCredit(row)}>
                    {intl('SCREEN.INVOICES.ACTIONS.ADD_CREDITS', { store: row?.store?.name })}
                  </Button>
                )}
                {false && (
                  <Button
                    variant="text"
                    className={`left ${accessDisable(ACCESS.ACTION.CHARGE.CREATE)}`}
                    fullWidth
                    onClick={() => onAddCharge(row)}>
                    {intl('SCREEN.INVOICES.ACTIONS.ADD_CHARGE', { store: row?.store?.name })}
                  </Button>
                )}
              </>
            </PopOver>
          )}
          {row.type === PAYMENT && !row.paymentGroup.rejectedAt && (
            <PopOver
              component={
                <MuiButton className="left ellipsis" fullWidth>
                  <MoreVert className="pointer" />
                </MuiButton>
              }>
              <>
                <Button
                  variant="text"
                  className={`left ${accessDisable(ACCESS.ACTION.PAYMENT.REJECT)}`}
                  fullWidth
                  onClick={() => onRejectPayment(row.paymentGroup)}>
                  {intl('BUTTON.REJECT')}
                </Button>
              </>
            </PopOver>
          )}
        </>
      );
    },
    width: 100,
    minWidth: 30,
  },
];

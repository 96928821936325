import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Checkbox,
  TableSortLabel,
  Table as MTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  List,
  ListItem,
} from '@mui/material';
import Pagination from '@mui/lab/Pagination';

import intl, { Text } from '$gintl';
import { SORT } from '$gbusiness/enums';
import { util } from '$gbusiness/helpers';
import { excludeColumns, generateCollapses, generateSelects } from './utils';
import { MobileWrapper, Vwrapper } from './styles';
import { Desktop, Div, ENUMS, SPACE } from '$gstyles';
import {
  TableDisplayModel,
  TableStylesModel,
  defaultDisplay,
  defaultStyles,
  groupTable,
} from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { DragDropContextWrapper } from './droppables';
import { Add, Remove } from '@mui/icons-material';
import { localStorage } from '$gbusiness/services';

interface TableProps {
  display?: TableDisplayModel;
  styles: TableStylesModel;
  TABLE: Array<CellModel>;
  INNER_TABLE?: Array<CellModel>;
  innerStyles?: TableStylesModel;
  innerKey?: string;
  blankText?: any;
  blankDelete?: any;
  data: Array<any>;
  disableSortKey?: boolean;
  onChangePage?: Function;
  onChangeSort?: Function;
  onSelection?: Function;
  onClickRow?: (r) => void;
  onDrag?: Function;
  columns?: any;
  itemActions?: any;
  resetSelection?: boolean;
  className?: string;
  sortKey?: string;
  sortOrder?: string;
}

const Table: React.FC<TableProps> = React.memo(
  ({
    display = defaultDisplay,
    styles = defaultStyles,
    TABLE: TEMP_TABLE,
    INNER_TABLE,
    data: parentData,
    innerStyles,
    innerKey = 'items',
    columns,
    disableSortKey,
    className = '',
    blankText,
    blankDelete,
    onDrag,
    onChangePage = () => {},
    onSelection,
    onClickRow,
    onChangeSort = () => {},
    itemActions,
    resetSelection = false,
    sortKey: filterSortKey,
    sortOrder: filterSortOrder,
  }) => {
    const TABLE = excludeColumns(TEMP_TABLE, styles);
    const {
      page,
      pageSize: rowsPerPage = defaultDisplay.pageSize,
      totalDataSize = defaultDisplay.totalDataSize,
      sortKey: displaySortKey,
      sortOrder: displaySortOrder,
      isSelectable,
    } = display;
    const sortKey = filterSortKey || displaySortKey;
    const sortOrder = filterSortOrder || displaySortOrder;
    const selectable = isSelectable || onSelection;
    const hasInner = !!INNER_TABLE;
    const pageSize = rowsPerPage === 0 ? totalDataSize : rowsPerPage || totalDataSize;
    const {
      responsive = true,
      color,
      groupBy,
      groupColor,
      isStripped,
      cellPadding,
      checkWidth,
      stickyHeader,
      minWidth,
      maxWidth,
      disableSort,
      fontSize,
      fontColor,
      borderColor,
      headerHeight = 40,
      rowHeight = 40,
      setRowClass,
      hideSelectAll,
    } = styles;
    const [checkedRows, setCheckedRows] = useState<Array<boolean>>(generateSelects(pageSize));
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [collapses, setCollapses] = useState<Array<any>>(
      generateCollapses(pageSize, styles?.defaultCollapse),
    );
    const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);
    const numberOfPages = Math.floor((totalDataSize - 1) / pageSize) + 1;
    const data = groupBy ? groupTable(parentData, groupBy) : parentData;

    const disableSorting = disableSort || disableSortKey;

    useEffect(() => {
      setCheckedRows(generateSelects(pageSize));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSelection]);

    useEffect(() => {
      const sorting = {
        sortKey,
        sortOrder,
        ref: 'TABLE' + TABLE.length,
      };
      localStorage.setStorageObj('sorting', sorting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey, sortOrder]);

    const collpaseRow = (i) => {
      const rows = [...collapses];
      rows[i] = !rows[i];
      setCollapses(rows);
    };
    const checkRow = (i) => {
      const rows = [...checkedRows];
      rows[i] = !rows[i];
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
    };

    const checkAllRows = () => {
      const newSelectAll = !selectAll;
      const rows = generateSelects(pageSize, newSelectAll);
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
      setSelectAll(newSelectAll);
    };

    if (!data.length) {
      return <div></div>;
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
      display: 'flex',
      alignItems: 'center',
      ...(isDragging && { backgroundColor: 'rgba(0,0,0,.1)' }),
      ...draggableStyle,
    });

    const renderRow = (row, i, provided, snapshot) => {
      const cells = TABLE.filter((cell) => !cell.isHidden);
      const isRowChecked = checkedRows[i];
      if (blankText && !row.itemId && row?.itemName === 'BLANK') {
        return (
          <TableRow
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            key={i}
            component="div"
            className="virtualized-row blank-row">
            <TableCell
              component="div"
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              colSpan={cells.length + (selectable ? 1 : 0)}>
              {
                row.itemName === 'BLANK' ? (
                  <div>&nbsp;</div>
                ) : blankText ? (
                  blankText(row, i)
                ) : (
                  <div>&nbsp;</div>
                )
                // <input type="text" className="blank-field" data-index={i} defaultValue={row.itemName} />
              }
              {blankDelete && <div className="blank-delete">{blankDelete(row, i)}</div>}
            </TableCell>
          </TableRow>
        );
      }
      if (row.grouped) {
        return (
          <TableRow
            key={i}
            component="div"
            className={`virtualized-row grouped-row ${isRowChecked && 'checked-row'}`}>
            <TableCell component="div" className="grouped-cell" colSpan={cells.length + (selectable ? 1 : 0)}>
              {row.name}
            </TableCell>
          </TableRow>
        );
      }

      const innerKeyValue = _.get(row, innerKey);
      return (
        <React.Fragment key={i}>
          <TableRow
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            style={getItemStyle(snapshot?.isDragging, provided?.draggableProps?.style)}
            data-id={row?.id || i}
            data-index={i}
            component="div"
            className={`virtualized-row ${onClickRow ? 'pointer' : ''} ${row?.className || ''} ${
              setRowClass && setRowClass(row, i)
            } ${isRowChecked && 'checked-row'}`}
            onClick={onClickRow ? () => onClickRow(row) : undefined}>
            {hasInner && (
              <TableCell component="div" className="collapse" style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                {(innerKeyValue?.length || 0) > 0 && (
                  <div className="expand-container" onClick={() => collpaseRow(i)}>
                    {collapses[i] ? <Add /> : <Remove />}
                  </div>
                )}
              </TableCell>
            )}
            {selectable && (
              <TableCell
                component="div"
                className="center select-cell"
                style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                <Checkbox checked={isRowChecked} onClick={() => checkRow(i)} />
              </TableCell>
            )}
            {cells.map((cell, j) => {
              return (
                <TableCell
                  component="div"
                  key={j}
                  className={`${cell.align || ''} ${cell.className || ''}`}
                  style={{
                    flex: `0 1 ${cell.width}px`,
                    ...(cell.minWidth && { minWidth: cell.minWidth }),
                    ...(cell.maxWidth && { maxWidth: cell.maxWidth }),
                  }}>
                  {j === 0 && blankText && !row.itemId ? (
                    blankText(row, i)
                  ) : (
                    <>
                      {cell.component && cell.component(row, itemActions, i)}
                      {!cell.component && _.get(row, cell.value)}
                    </>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          {INNER_TABLE && innerKeyValue && innerKeyValue.length > 0 && (
            <TableRow
              component="div"
              className={`virtualized-row inner-table ${collapses[i] && 'flattened'} ${
                setRowClass && setRowClass(row, i)
              }`}>
              <TableCell component="div" colSpan={cells.length + (selectable ? 1 : 0)}>
                <Table
                  className="inner-table-table"
                  TABLE={INNER_TABLE}
                  styles={innerStyles || defaultStyles}
                  data={innerKeyValue}
                />
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    };

    const rendered = (
      <DragDropContextWrapper onDrag={onDrag} isGrouped={!!groupBy}>
        <Vwrapper
          className={`table-wrapper ${className} ${stickyHeader ? 'sticky-header' : ''}`}
          minWidth={minWidth}
          maxWidth={maxWidth}
          groupColor={groupColor || 'medium'}
          headerColor={color}
          fontSize={fontSize}
          fontColor={fontColor}
          stripped={isStripped}
          cellPadding={cellPadding}
          borderColor={borderColor}
          rowHeight={rowHeight}
          headerHeight={headerHeight!}>
          <MTable component="div" size="small" className="virtualized-table">
            {TABLE && (
              <TableHead component="div">
                <TableRow component="div" className="virtualized-row">
                  {hasInner && (
                    <TableCell
                      component="div"
                      className="center collapse"
                      style={{ flex: `0 1 ${checkWidth || 40}px` }}></TableCell>
                  )}
                  {selectable && (
                    <TableCell
                      component="div"
                      className="center select-cell"
                      style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                      <span className="selectCol">
                        {!hideSelectAll && (
                          <Checkbox checked={selectAll} className="select-all" onClick={checkAllRows} />
                        )}
                      </span>
                    </TableCell>
                  )}
                  {TABLE.filter((cell) => !cell.isHidden).map((cell, i) => {
                    const cellSortKey = cell.sortKey || cell.value;
                    const cellSortOrder = sortOrder === SORT.ASC ? 'asc' : 'desc';
                    return (
                      <TableCell
                        component="div"
                        key={i}
                        className={`${cell.align || ''} ${cell.className || ''}`}
                        style={{
                          flex: `0 1 ${cell.width}px`,
                          ...(cell.minWidth && { minWidth: cell.minWidth }),
                          ...(cell.maxWidth && { maxWidth: cell.maxWidth }),
                        }}>
                        {cell.sortable && !disableSorting && (
                          <TableSortLabel
                            className={cell.align || ''}
                            active={sortKey === cellSortKey}
                            direction={cellSortOrder}
                            onClick={() => {
                              onChangeSort(
                                cellSortKey,
                                sortKey !== cellSortKey ? cell.sortable || '' : util.changeSort(sortOrder),
                              );
                              // onHandleSort(cellSortKey, cellSortOrder);
                            }}>
                            <Text k={cell.label}>{cell.labelText}</Text>
                          </TableSortLabel>
                        )}
                        {(!cell.sortable || disableSorting) && <Text k={cell.label}>{cell.labelText}</Text>}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}

            {onDrag && !groupBy ? (
              <Droppable
                droppableId="droppable"
                renderClone={(provided, snapshot, rubric) => {
                  const i = rubric.source.index;
                  const row = data[i];
                  return renderRow(row, i, provided, snapshot);
                }}>
                {(provided, snapshot) => (
                  <TableBody component="div" {...provided.droppableProps} ref={provided.innerRef}>
                    {data.map((row, i) => (
                      <Draggable
                        isDragDisabled={!onDrag}
                        key={i.toString()}
                        draggableId={(row.id || i).toString()}
                        index={i}>
                        {(provided, snapshot) => renderRow(row, i, provided, snapshot)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            ) : (
              <TableBody component="div" className="table-body">
                {data.map((row, i) => renderRow(row, i, null, null))}
              </TableBody>
            )}
          </MTable>

          {numberOfPages > 1 && (
            <Div className="pagination" padding={`${SPACE.MEDIUM} 0 `}>
              <Pagination
                count={numberOfPages}
                siblingCount={isMobile ? 1 : 2}
                page={page}
                boundaryCount={1}
                showFirstButton={!isMobile}
                showLastButton={!isMobile}
                onChange={(e, pageNumber) => {
                  onChangePage(pageNumber);
                }}
              />
            </Div>
          )}
        </Vwrapper>
      </DragDropContextWrapper>
    );

    if (responsive) {
      return (
        <DragDropContext>
          <MobileWrapper groupColor={groupColor}>
            <List className={`list ${className}`}>
              {data.map((row, index) => {
                const isRowChecked = checkedRows[index];
                if (row.grouped) {
                  return (
                    <ListItem key={index} className="grouped-row">
                      <div className="grouped-cell">{row.name}</div>
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    className={`${setRowClass && setRowClass(row, index)} ${isRowChecked && 'checked-row'}`}
                    key={index}
                    onClick={onClickRow ? () => onClickRow(row) : undefined}>
                    {selectable && <Checkbox checked={checkedRows[index]} onClick={() => checkRow(index)} />}
                    {TABLE.filter((cell) => !cell.isHidden && cell.className?.includes('image')).map(
                      (cell, i) => {
                        return (
                          <div key={i} className={`${cell.className || ''}`}>
                            {cell.component && cell.component(row, itemActions, i)}
                          </div>
                        );
                      },
                    )}
                    <div className="text">
                      {TABLE.filter((cell) => !cell.isHidden && !cell.className?.includes('action')).map(
                        (cell, i) => {
                          if (cell.className?.includes('desktop') || cell.className?.includes('image'))
                            return null;
                          return (
                            <div key={i} className={`${cell.className || ''}`}>
                              {!(cell.className || '').includes('no-label') && (
                                <span className="label">{intl(cell.label)}: </span>
                              )}
                              <span className="value">
                                {cell.component && cell.component(row, itemActions, index)}
                                {!cell.component && _.get(row, cell.value)}
                              </span>
                            </div>
                          );
                        },
                      )}
                    </div>
                    {TABLE.filter((cell) => !cell.isHidden && cell.className?.includes('action')).map(
                      (cell, i) => {
                        return (
                          <div key={i} className={`${cell.className || ''}`}>
                            {cell.component && cell.component(row, itemActions, index)}
                            {!cell.component && _.get(row, cell.value)}
                          </div>
                        );
                      },
                    )}
                  </ListItem>
                );
              })}
            </List>
          </MobileWrapper>
          <Desktop>{rendered}</Desktop>
        </DragDropContext>
      );
    } else return rendered;
  },
);

export default Table;

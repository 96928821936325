import FileModel, { deriveRawToFile } from './file';

export default interface BroadcastModel {
  id: number;
  stores: Array<any>;
  subject: string;
  body: string;
  fromName: string;
  fromEmail: string;
  files?: Array<FileModel>;
  createdAt: string;
  settings?: any;
}

export const deriveRawToBroadcast = (raw) => {
  return {
    id: raw.id,
    stores: raw.stores,
    subject: raw.subject,
    body: raw.body,
    fromName: raw.fromName,
    fromEmail: raw.fromEmail,
    files: (raw.files || []).map(deriveRawToFile),
    createdAt: raw.createdAt,
    ...(raw.settings && { settings: raw.settings }),
  };
};

export const initialBroadcast = {
  id: 0,
  stores: [],
  subject: '',
  body: '',
  fromName: '',
  fromEmail: '',
  files: [],
  createdAt: '',
};

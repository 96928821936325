import { configs } from '$configs';
import { Link } from '$gcomponents/primitives';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToUser } from '$gbusiness/models/user';
import PATH from '$gbusiness/enums/paths';
import intl from '$gintl';

export const USER_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.users.general,
    deriveToModel: deriveRawToUser,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 400,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

const USER_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.USERS.COLS.ID',
    value: 'userLevel',
    align: 'center',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.USERS.COLS.FIRST_NAME',
    value: 'firstName',
    component: (row) => <Link route={`${PATH.USERS}/${row.userId}`}>{row.firstName}</Link>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.USERS.COLS.LAST_NAME',
    value: 'lastName',
    component: (row) => <Link route={`${PATH.USERS}/${row.userId}`}>{row.lastName}</Link>,
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.USERS.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.USERS.COLS.LEVEL',
    value: 'userLevel',
    component: (row) => {
      return intl('INPUT.LEGEND.LABEL.' + row.level?.label)
    },
    sortable: SORT.ASC,
    width: 400,
  },
];

export default USER_TABLE;
